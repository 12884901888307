<template>
  <div id="app">
    <div class="hide-on-print">
      <div class="app-container">
        <MPSpinner v-if="loading" />
        <router-view name="sidebar" class="nav d-none d-lg-flex" />
        <router-view name="bottombar" class="bottomnav d-flex d-lg-none" />
        <router-view class="main" />
      </div>
      <MPToast :message="toastMessage" />
      <!-- Refresh modal when meal plan is out of sync -->
      <MPRefreshPageModal
        :visible="showVersionUpdatedModal"
        modalId="version-updated-refresh-page-modal"
        :icon="require('@/assets/icons/refresh-updates.svg')"
        text="We just made some updates to the meal planner. Please refresh the page to continue."
        helpText="Changelog of Updates"
        helpLink="https://support.forksmealplanner.com/article/1pX9QnzNLJ-changelog-of-updates"
      >
      </MPRefreshPageModal>

      <!-- Refresh modal when new version is released -->
      <MPRefreshPageModal
        :visible="showMealPlanChangeModal"
        modalId="meal-plan-changed-refresh-page-modal"
        :icon="require('@/assets/icons/refresh-meal-plan.svg')"
        text="Please refresh the page to ensure that you are seeing the most recent version of your meal plan."
        helpText="Help & FAQ"
        helpLink="https://support.forksmealplanner.com/"
      >
      </MPRefreshPageModal>

      <!-- Show Upsell Promo Modal -->
      <!-- Remove upsell promo, deprecated -->
      <!-- <UpsellPromoModal
        :visible="showUpsellPromoModal"
        v-bind:promo="upsellModalData"
      /> -->

      <!-- Show user subscribed modal when user starts paying -->
      <MPUserSubscribedModal :visible="showUserSubscribedModal" />

      <!-- Both versions of the print/download modal -->
      <MPPrintModal
        :visible.sync="showPrintModal"
        :printModalMode="'PRINT'"
        :printOptions="printOptions"
      />
      <MPPrintModal
        :visible.sync="showDownloadModal"
        :printModalMode="'DOWNLOAD'"
        :printOptions="printOptions"
      />
      <!-- Connection error banner -->
      <!--
      TODO: DISQUS hotfix.
      <MPNotification
        v-if="connectionError"
        type="error"
        :image="require('@/assets/icons/error.svg')"
        class="full-with-navbar add-lr-padding"
        :class="{ full: !routeHasSideNavbar() }"
        :showCloseButton="false"
      >
        <span
          >Please check your internet connection and
          <MPWhiteLink href @click.prevent="reloadPage()">try again</MPWhiteLink
          >.</span
        >
      </MPNotification>
      -->
      <!-- Server error banner -->
      <!-- <MPNotification
        v-if="serverError"
        type="error"
        :image="require('@/assets/icons/error.svg')"
        class="full-with-navbar add-lr-padding"
        :class="{ full: !routeHasSideNavbar() }"
        :showCloseButton="false"
      >
        <span
          >Sorry, there was an error. Please try
          <MPWhiteLink href @click.prevent="reloadPage()"
            >reloading</MPWhiteLink
          >
          the page or
          <MPWhiteLink href @click.prevent="openIntercom()"
            >contact us</MPWhiteLink
          >
          if this keeps happening.</span
        >
      </MPNotification> -->
      <AffiliateOffer :isLoggedIn="isLoggedIn" @contact="openIntercom()" />
      <PromoOffer :isLoggedIn="isLoggedIn" />
      <!-- Payment Late banner -->
      <MPNotification
        v-if="paymentLateBannerVisible && paymentLate"
        type="error"
        :image="require('@/assets/icons/error.svg')"
        class="full-with-navbar add-lr-padding"
        :showCloseButton="true"
        @onCloseActionSelect="setPaymentLateBannerVisible(false)"
      >
        <span>
          We could not process this month’s payment. Please
          <MPWhiteLink href @click.prevent="checkCreditCard()">
            update your billing information </MPWhiteLink
          >.
        </span>
      </MPNotification>
      <!-- Subscription will soon expire banner -->
      <MPNotification
        v-if="subscriptionExpiresSoonBannerVisible && willPlanSoonExpire"
        type="error"
        :image="require('@/assets/icons/error.svg')"
        class="full-with-navbar add-lr-padding"
        :showCloseButton="true"
        @onCloseActionSelect="setSubscriptionExpiresSoonBannerVisible(false)"
      >
        <span
          >Your subscription will soon expire. You can
          <MPWhiteLink href @click.prevent="goToBilling()"
            >reactivate your plan</MPWhiteLink
          >
          here.</span
        >
      </MPNotification>
      <!-- Existing paying members trying to access discount banners -->

      <!-- BANNER: custom user INFO message -->
      <MPNotification
        v-if="message && message.severity == 'info'"
        type="offer"
        :image="require('@/assets/icons/check-green.svg')"
        class="full-with-navbar add-lr-padding"
        :class="{ full: !routeHasSideNavbar() }"
        :showCloseButton="true"
        @onCloseActionSelect="removeMessage()"
      >
        <span>{{ message.text }}</span>
      </MPNotification>
      <!-- BANNER: custom user ERROR AND WARNING message -->
      <MPNotification
        v-if="message && message.severity !== 'info'"
        type="error"
        :image="require('@/assets/icons/error.svg')"
        class="full-with-navbar add-lr-padding"
        :class="{ full: !routeHasSideNavbar() }"
        :showCloseButton="true"
        @onCloseActionSelect="removeMessage()"
      >
        <span>{{ message.text }}</span>
      </MPNotification>
      <MPNotification
        type="offer"
        v-if="addPersonalRecipeMessage"
        :title="addPersonalRecipeMessage"
      ></MPNotification>
      <MPNotificationStore
        v-if="notificationVisible"
        :image="notificationIcon"
        :title="notificationText"
        :isHtml="notificationHtml.hasHtml"
        :isAction="notificationActions.isAction"
        :actionText="notificationActions.actionText"
        :closeActionText="notificationActions.closeActionText"
        @onActionSelect="notificationActions.action"
        @onCloseActionSelect="notificationActions.closeAction"
        :color="notificationColor"
        :full="isFull"
      >
        <component
          v-if="notificationHtml.hasHtml"
          :is="notificationHtml.component"
        />
      </MPNotificationStore>
    </div>
    <div class="print-road-block">
      <img src="@/assets/icons/print-roadblock.svg" />
      <p class="text-center w-50 mt-5">
        To print, please use the dedicated ‘print’ icon located on the
        dashboard, grocery list, weekend prep or recipe pages.
      </p>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { mapGetters, mapActions } from 'vuex'
import {
  MPSpinner,
  MPRefreshPageModal,
  MPUserSubscribedModal,
  MPPrintModal,
  MPNotification,
  MPWhiteLink,
} from '@/components/common'
import HomeApi from './api/HomeApi'
import environment from '@/environment'
import 'url-search-params-polyfill'
import { hideIntercomWidget } from '@/utils/intercom'
import DetectDeviceMixin from '@/mixins/DetectDeviceMixin'
import { getCurrentDate } from '@/utils/dates'
import { EventBus } from '@/events/EventBus.js'
import ProfitWell from '@/externalServices/ProfitWell'
import FullStory from '@/externalServices/FullStory'
import { SET_USER_DATA_INIT_PROMISE } from '@/store/mutation-types'
import UpsellPromoModal from '@/components/subscriptions/UpsellPromoModal'
import { events } from '@/utils/mixpanel.events'
import AffiliateOffer from '@/components/affiliate/AffiliateOffer'
import PromoOffer from '@/components/promoOffer/PromoOffer'
import MPNotificationStore from './components/common/MPNotificationStore.vue'
import NotificationsMixin from './mixins/NotificationsMixin'
import ServerError from '@/components/common/Notifications/ServerError.vue'

export default {
  components: {
    AffiliateOffer,
    MPWhiteLink,
    MPNotification,
    MPSpinner,
    MPRefreshPageModal,
    MPUserSubscribedModal,
    MPPrintModal,
    UpsellPromoModal,
    PromoOffer,
    MPNotificationStore,
  },
  mixins: [DetectDeviceMixin, NotificationsMixin],
  computed: {
    ...mapGetters({
      loading: 'globals/loading',
      accessToken: 'user/accessToken',
      isLoggedIn: 'user/isLoggedIn',
      isMealPlannerLocked: 'globals/isMealPlannerLocked',
      isActiveUser: 'user/isActive',
      isFreeUser: 'user/isFree',
      isExpiredUser: 'user/isExpired',
      subscriptionName: 'user/subscription',
      mealPlan: 'globals/mealPlan',
      showVersionUpdatedModal: 'globals/showVersionUpdatedModal',
      showMealPlanChangeModal: 'globals/showMealPlanChangeModal',
      showUserSubscribedModal: 'globals/showUserSubscribedModal',
      showUpsellPromoModal: 'user/showUpsellPromoModal',
      upsellModalData: 'user/upsellModalData',
      printOptions: 'globals/printOptions',
      paymentLateBannerVisible: 'globals/paymentLateBannerVisible',
      subscriptionReactivateBannerVisible:
        'globals/subscriptionReactivateBannerVisible',
      subscriptionExpiresSoonBannerVisible:
        'globals/subscriptionExpiresSoonBannerVisible',
      serverError: 'globals/serverError',
      connectionError: 'globals/connectionError',
      toastMessage: 'globals/toastMessage',
      paymentLate: 'headers/paymentLate',
      mustReactivatePlan: 'user/mustReactivatePlan',
      willPlanSoonExpire: 'user/willPlanSoonExpire',
      userEmail: 'user/email',
      userName: 'user/name',
      userId: 'user/id',
      affiliateCode: 'user/affiliateCode',
      affiliateName: 'user/affiliateName',
      addPersonalRecipeMessage: 'AddCustomRecipe/message',
      permissions: 'user/permissions',
      defaultMealPlan: 'user/defaultMealPlan',
      message: 'user/message',
      // notifications
      notificationVisible: 'notifications/visible',
      notificationActions: 'notifications/actions',
      notificationText: 'notifications/text',
      notificationHtml: 'notifications/html',
      notificationIcon: 'notifications/icon',
      notificationColor: 'notifications/color',
      isFull: 'notifications/full',
    }),
    showPrintModal: {
      get() {
        return this.$store.state.globals.showPrintModal
      },
      set(show) {
        this.$store.dispatch('globals/showPrintModal', show, { root: true })
      },
    },
    showDownloadModal: {
      get() {
        return this.$store.state.globals.showDownloadModal
      },
      set(show) {
        this.$store.dispatch('globals/showDownloadModal', show, { root: true })
      },
    },
    planType() {
      if (this.isExpiredUser) {
        return 'expired'
      }

      if (this.isFreeUser) {
        return 'free trial'
      }

      if (this.isActiveUser) {
        return this.subscriptionName
      }

      return 'unknown'
    },
  },
  methods: {
    removeMessage() {
      this.$store.dispatch('user/removeMessage')
    },
    routeHasSideNavbar() {
      return this.$router.currentRoute.matched[0].components.sidebar
    },
    reloadPage() {
      window.location.reload(true)
    },
    openIntercom() {
      this.$intercom.show()
    },
    checkCreditCard() {
      this.$router.push('/settings/billing?openModal=updateCardInfo')
    },
    goToBilling() {
      this.$router.push('/reactivate')
    },
    bootUp() {
      if (this.isMobile().any) {
        setTimeout(() => {
          hideIntercomWidget(true)
        }, 10)
      }

      this.$store.commit(
        `user/${SET_USER_DATA_INIT_PROMISE}`,
        this.$store.dispatch('user/refreshUserData')
      )

      this.$store.getters['user/userDataInitPromise'].then(() => {
        // Free users go to sample meal plan
        // Active users go to current meal plan
        this.$posthog.phInstance.identify(this.userId, {
          email: this.userEmail,
          name: this.userName,
        })
        if (!this.mealPlan) {
          let mealPlan = this.defaultMealPlan || 'sample'
          if (this.permissions.canAccessNonSampleMealPlans) {
            mealPlan = this.defaultMealPlan || 'current'
          }
          this.$store.dispatch('globals/setMealPlan', mealPlan, {
            root: true,
          })
        }
        this.$store.dispatch('dashboard/refreshDashboard')
        this.initAfterLogin()

        if (this.isMobile().any) {
          this.$store.dispatch('user/setIntercomUserAttributes', {
            mobile_access: getCurrentDate(),
          })
        }
      })
      this.$store.dispatch('recipe/initSystemData')
      this.$store.dispatch('dashboard/refreshMealPlans')
      this.$store.dispatch('user/setCancelUrl')
    },
    initAfterLogin() {
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: this.userEmail,
          id: this.userId,
        })
      })
      this.$intercom.boot({
        user_id: this.userId,
        name: this.userName,
        email: this.userEmail,
      })
      if (this.isMobile().any) {
        setTimeout(() => {
          hideIntercomWidget(true)
        }, 100)
      }
      this.$analytics.identify(this.userId, this.userEmail)
      ProfitWell.start(this.userEmail)
      FullStory.then((FS) => {
        const fsObj = {
          displayName: this.userName,
          email: this.userEmail,
        }
        if (this.affiliateCode) {
          fsObj['promotion_name'] = this.affiliateCode
          fsObj['affiliate'] = this.affiliateName
        }

        FS.identify(String(this.userId), fsObj)
      })
      // set Ambassador email variable
      window.mbsy_user_email = this.userEmail
    },
    shutdown() {
      try {
        this.$intercom.shutdown()
      } catch (error) {
        // intercom might not have been initialized yet
      }
      delete window.mbsy_user_email
    },
    checkAppParams() {
      this.checkCampaign()
      this.checkABGroup()
      this.checkImpactPartnerId()
      this.checkAmbassadorId()
      this.checkMixpanelDistinctId()
      this.checkFokRef()
      this.checkGiftCode()
      this.checkIfIsRecipe()
    },
    checkIfIsRecipe() {
      const recipeId = this.$routeQuery.get('fokrecipeid')
      if (recipeId) {
        this.$router.push(`/recipe/${recipeId}`)
      }
    },
    checkCampaign() {
      const cookieCampaign = this.$cookie.get('campaign', {
        domain: environment.cookieDomain,
      })
      if (cookieCampaign) {
        // delete campaign cookie that might have been set by the landing page
        this.$cookie.delete('campaign', { domain: environment.cookieDomain })
      }
      const campaign = this.$routeQuery.get('campaign') || cookieCampaign
      if (campaign) {
        this.$store.dispatch('globals/setCampaign', campaign, { root: true })
        if (this.isLoggedIn) {
          HomeApi.addToCampaign(campaign).then(function () {
            this.$cookie.delete('campaign', {
              domain: environment.cookieDomain,
            })
          })
        }
      }
    },
    checkABGroup() {
      const cookieAbGroup = this.$cookie.get('abgroup', {
        domain: environment.cookieDomain,
      })
      const abGroup = this.$routeQuery.get('abgroup') || cookieAbGroup
      this.$store.dispatch('globals/setABGroup', abGroup, { root: true })
      if (cookieAbGroup) {
        // delete abgroup cookie that might have been set by the landing page
        this.$cookie.delete('abgroup', { domain: environment.cookieDomain })
      }
    },
    checkImpactPartnerId() {
      const storedPartnerId = this.$cookie.get('partner_id', {
        domain: environment.cookieDomain,
      })
      const impactRadiusPartnerId =
        this.$routeQuery.get('partner_id') || storedPartnerId
      if (!impactRadiusPartnerId) {
        return
      }
      if (impactRadiusPartnerId.indexOf('delete') != -1) {
        this.$cookie.delete('partner_id', { domain: environment.cookieDomain })
        this.$store.dispatch('globals/setImpactPartnerId', null, { root: true })
      } else {
        if (!storedPartnerId) {
          var now = new Date()
          var exp = new Date(
            now.getFullYear(),
            now.getMonth() + 1,
            now.getDate()
          )
          this.$cookie.set('partner_id', impactRadiusPartnerId, {
            expires: exp,
            domain: environment.cookieDomain,
          })
          this.$store.dispatch(
            'globals/setImpactPartnerId',
            impactRadiusPartnerId,
            { root: true }
          )
        } else {
          this.$store.dispatch('globals/setImpactPartnerId', storedPartnerId, {
            root: true,
          })
        }
      }
    },
    checkAmbassadorId() {
      const ambassadorId = window.mbsy_short_code
      this.$store.dispatch('globals/setAmbassadorId', ambassadorId, {
        root: true,
      })
    },
    checkMixpanelDistinctId() {
      const distinctId = this.$routeQuery.get('distinct_id')
      if (distinctId) this.$analytics.setMixpanelDistinctId(distinctId)
    },
    checkFokRef() {
      const fokref = this.$routeQuery.get('fokref')
      if (fokref) this.$analytics.track('mp fokref', { fokref })
    },
    checkGiftCode() {
      const giftCode = this.$routeQuery.get('gift_code')
      if (giftCode) {
        this.$store.dispatch('globals/setGiftCode', giftCode, {
          root: true,
        })
        this.$analytics.track('mp giftcode', { giftCode })
      }
    },
    setPaymentLateBannerVisible(visible) {
      this.$store.dispatch('globals/setPaymentLateBannerVisible', visible, {
        root: true,
      })
    },
    setSubscriptionReactivateBannerVisible(visible) {
      this.$store.dispatch(
        'globals/setSubscriptionReactivateBannerVisible',
        visible,
        { root: true }
      )
    },
    setSubscriptionExpiresSoonBannerVisible(visible) {
      this.$store.dispatch(
        'globals/setSubscriptionExpiresSoonBannerVisible',
        visible,
        { root: true }
      )
    },
  },
  watch: {
    isLoggedIn: {
      handler: function (loggedIn) {
        if (loggedIn) {
          this.bootUp()
        } else {
          this.shutdown()
        }
      },
      immediate: true,
    },
    '$intercom.visible': function (isVisible) {
      if (isVisible) {
        this.$store.dispatch('analytics/track', {
          eventName: events.support.contact,
        })
      }
    },
    isActiveUser: {
      handler: function (isActiveUser) {
        if (isActiveUser) {
          document.body.classList.remove('is-free-user')
        } else {
          document.body.classList.add('is-free-user')
        }
      },
      immediate: true,
    },
    isMealPlannerLocked: {
      handler: function (isMealPlannerLocked) {
        if (isMealPlannerLocked) {
          document.body.classList.add('meal-planner-locked')
        } else {
          document.body.classList.remove('meal-planner-locked')
        }
      },
      immediate: true,
    },
    serverError(value) {
      if (value) {
        this.errorNotification(ServerError, this.routeHasSideNavbar())
      }
    },
  },
  mounted() {
    this.checkAppParams()

    if (this.isMobile().any) {
      setTimeout(() => {
        hideIntercomWidget(true)
      }, 1000)
    }

    if (!this.isLoggedIn) {
      this.$analytics.impactRadiusIdentify('', '')
    }

    EventBus.$on('updateIntercom', (payload) => {
      // Triggered from the store, when we need to send data to intercom
      this.$intercom.update(payload)
    })
    EventBus.$on('logout', () => {
      // TODO add any necessary clean up code here
      // remove mixpanel from the system
      // this.$analytics.logOut()
      setTimeout(() => {
        this.$router.go()
      }, 100)
    })
    EventBus.$on('analyticsTrack', ({ event, properties }) => {
      this.$analytics.track(event, properties)
    })
  },
}
</script>

<style lang="scss">
.app-container {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.bottomnav {
  position: fixed;
  bottom: 0;
}

.nav {
  width: 312px;
}

.main {
  flex: 1;
}

.print-road-block {
  display: none;
}
@media print {
  .print-road-block {
    display: flex;
    overflow: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    height: 100vh;
    font-size: 1.5rem;
    line-height: 1.69;
    img {
      width: 180px;
    }
  }
  .print-road-block::-webkit-scrollbar {
    width: 0 !important;
  }
  .hide-on-print {
    display: none;
  }
  body {
    background: none !important;
  }
}

.ot-floating-button__close {
  padding-left: 28%;
  padding-top: 28%;
}

@include media-breakpoint-up(lg) {
  .ot-floating-button {
    margin-left: 310px;
    margin-bottom: 10px;
  }
}
@include media-breakpoint-down(md) {
  $navbar-height: 90px;
  $upgrade-banner-height: 101px;
  .intercom-lightweight-app-launcher,
  .ot-floating-button {
    bottom: $navbar-height !important;
  }
  .is-free-user {
    .intercom-lightweight-app-launcher,
    .ot-floating-button {
      bottom: $navbar-height + $upgrade-banner-height !important;
    }
  }
  .meal-planner-locked {
    .intercom-lightweight-app-launcher,
    .ot-floating-button {
      bottom: $navbar-height !important;
    }
  }
}

/* Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 7px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: $clr-navy;
  border-radius: 9999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: $dark-navy;
}

/* scroll bar corner */
::-webkit-scrollbar-corner {
  background-color: $transparent;
}
</style>
