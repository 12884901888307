const generateAsyncStatus = (type) => {
  return {
    PENDING: `${type}_PENDING`,
    SUCCESS: `${type}_SUCCESS`,
    ERROR: `${type}_ERROR`,
  }
}
export const RECEIVE_SEARCH_RECIPES = 'RECEIVE_SEARCH_RECIPES'
export const RECEIVE_RECIPES_CATEGORIES = 'RECEIVE_RECIPES_CATEGORIES'
export const RECEIVE_RECIPE_BOX_FILTERS = 'RECEIVE_RECIPE_BOX_FILTERS'
export const ADD_RECIPE_BOX_INGREDIENT_FILTER =
  'ADD_RECIPE_BOX_INGREDIENT_FILTER'
export const RECEIVE_AVAILABLE_PLANS = 'RECEIVE_AVAILABLE_PLANS'
export const SET_SEARCH_RECIPES_STATUS = 'SET_SEARCH_RECIPES_STATUS'
export const UPDATE_RECIPE_PAGE = 'UPDATE_RECIPE_PAGE'
export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT'
export const SET_RECIPES_HAS_MORE_PAGES = 'SET_RECIPES_HAS_MORE_PAGES'
export const SET_RECIPES_QUERY_STATUS = 'SET_RECIPES_QUERY_STATUS'
export const GET_RECIPES = 'GET_RECIPIES'
export const SET_RECIPE_FILTERS = 'SET_RECIPE_FILTERS'
export const UPDATE_RECIPE_FILTERS_SELECTED = 'UPDATE_RECIPE_FILTERS_SELECTED'
export const UPDATE_RECIPE_INGREDIENTS_FILTERS_SELECTED =
  'UPDATE_RECIPE_INGREDIENTS_FILTERS_SELECTED'
export const UPDATE_RECIPE_FILTERS_INCLUDED_SELECTED =
  'UPDATE_RECIPE_FILTERS_INCLUDED_SELECTED'
export const REFRESH_RECIPES_CACHE = 'REFRESH_RECIPES_CACHE'
export const SET_ALL_RECIPES_STATUS = 'SET_ALL_RECIPES_STATUS'
export const SET_LOCAL_FAVORITE = 'SET_LOCAL_FAVORITE'
export const REMOVE_LOCAL_FAVORITE = 'REMOVE_LOCAL_FAVORITE'
export const TOGGLE_RECIPE_FAVORITE = 'TOGGLE_RECIPE_FAVORITE'
export const UPDATED_FAVORITE = 'UPDATED_FAVORITE'
export const UPDATE_FAVORITE_PRIMARY_RECIPE = 'UPDATE_FAVORITE_PRIMARY_RECIPE'
export const UPDATE_FAVORITE_ALTERNATIVE_RECIPE =
  'UPDATE_FAVORITE_ALTERNATIVE_RECIPE'
export const UPDATE_LOCAL_RECIPES = 'UPDATE_LOCAL_RECIPES'
export const SET_CURRENT_PLAN = 'SET_CURRENT_PLAN'
export const SET_IS_ADD_RECIPE_BOX = 'SET_IS_ADD_RECIPE_BOX'
export const SET_SHOW_PLAN_DISABLED = 'SET_SHOW_PLAN_DISABLED'
export const SET_SELECTED_FILTERS_TO_DEFAULT = 'SET_SELECTED_FILTERS_TO_DEFAULT'
export const SET_DEFAULT_FILTER_BY_SELECTED = 'SET_DEFAULT_FILTER_BY_SELECTED'
export const SET_DEFAULT_KID_APPROVED_SELECTED =
  'SET_DEFAULT_KID_APPROVED_SELECTED'
export const SET_DEFAULT_NO_WEEKEND_PREP_SELECTED =
  'SET_DEFAULT_NO_WEEKEND_PREP_SELECTED'
export const SET_DEFAULT_PRESSURE_COOKER_SELECTED =
  'SET_DEFAULT_PRESSURE_COOKER_SELECTED'
export const SET_DEFAULT_SORT_BY_SELECTED = 'SET_DEFAULT_SORT_BY_SELECTED'
export const SET_FILTER_BY_TYPE_SELECTED = 'SET_FILTER_BY_TYPE_SELECTED'
export const SET_FILTER_BY_SELECTED = 'SET_FILTER_BY_SELECTED'
export const SET_KID_APPROVED_SELECTED = 'SET_KID_APPROVED_SELECTED'
export const SET_NO_WEEKEND_PREP_SELECTED = 'SET_NO_WEEKEND_PREP_SELECTED'
export const SET_PRESSURE_COOKER_SELECTED = 'SET_PRESSURE_COOKER_SELECTED'
export const SET_SORT_BY_SELECTED = 'SET_SORT_BY_SELECTED'
export const SET_SHOW_FAVORITES_ONLY = 'SET_SHOW_FAVORITES_ONLY'
export const SET_PREP_TIME_SELECTED = 'SET_PREP_TIME_SELECTED'
export const SET_FILTER_BY_RATING_SELECTED = 'SET_FILTER_BY_RATING_SELECTED'
export const SET_INGREDIENTS_COUNT_SELECTED = 'SET_INGREDIENTS_COUNT_SELECTED'
export const SET_CALORIES_COUNT_SELECTED = 'SET_CALORIES_COUNT_SELECTED'

export const SET_FILTER_TAG_SELECTED = 'SET_FILTER_TAG_SELECTED'
export const SET_PRIMARY_AS_GENERAL = 'SET_PRIMARY_AS_GENERAL'

// Grocery list
export const GET_SHOPPING_DATA_BY_AISLE = generateAsyncStatus(
  'GET_SHOPPING_DATA_BY_AISLE'
)
export const GET_SHOPPING_DATA_BY_RECIPE = generateAsyncStatus(
  'GET_SHOPPING_DATA_BY_RECIPE'
)
export const GET_SHOPPING_DATA_TIPS = generateAsyncStatus(
  'GET_SHOPPING_DATA_TIPS'
)
export const GET_AISLE_LIST = generateAsyncStatus('GET_AISLE_LIST')
export const GET_UNIT_LIST = generateAsyncStatus('GET_UNIT_LIST')
export const SET_UNIT_ID = 'SET_UNIT_ID'
export const SET_AISLE_ID = 'SET_AISLE_ID'
export const SET_QUANTITY = 'SET_QUANTITY'
export const SET_CURRENT_AISLE_CLICK = 'SET_CURRENT_AISLE_CLICK'
export const SORT_BY_AISLE = 'SORT_BY_AISLE'
export const SORT_BY_RECIPE = 'SORT_BY_RECIPE'
export const SORT_BY_AISLE_EXCLUDED = 'SORT_BY_AISLE_EXCLUDED'
export const SORT_BY_RECIPE_EXCLUDED = 'SORT_BY_RECIPE_EXCLUDED'
export const SET_SELECTED_INGREDIENT_FOR_EDIT =
  'SET_SELECTED_INGREDIENT_FOR_EDIT'
export const CLEAR_GROCERY_LIST_DATA = 'CLEAR_GROCERY_LIST_DATA'
export const SET_GROCERY_LIST_PENDING = 'SET_GROCERY_LIST_PENDING'

// dashboard
export const SET_DASHBOARD_TITLE = 'SET_DASHBOARD_TITLE'
export const SET_DASHBOARD_STATUS = 'SET_DASHBOARD_STATUS'
export const RECEIVE_DASHBOARD = 'RECEIVE_DASHBOARD'
export const SET_DASHBOARD_MEALS_STATUS = 'SET_DASHBOARD_MEALS_STATUS'
export const RECEIVE_DASHBOARD_MEALS = 'RECEIVE_DASHBOARD_MEALS'
export const SET_MEALS_VERSION = 'SET_MEALS_VERSION'
export const SET_MIN_LISTS_IN_DASHBOARD = 'SET_MIN_LISTS_IN_DASHBOARD'
export const SET_LISTS_NUMBER_IN_DASHBOARD = 'SET_LISTS_NUMBER_IN_DASHBOARD'
export const SET_DASHBOARD_QUEUE_STATUS = 'SET_DASHBOARD_QUEUE_STATUS'
export const SET_IS_USER_ACTIVE = 'SET_IS_USER_ACTIVE'
export const SET_MEAL_PLANS = 'SET_MEAL_PLANS'
export const MEAL_PLAN_SELECTED_TYPE = 'MEAL_PLAN_SELECTED_TYPE'
export const SAVE_AS_CUSTOM_PLAN_MODAL_VISIBLE =
  'SAVE_AS_CUSTOM_PLAN_MODAL_VISIBLE'
export const RENAME_CUSTOM_PLAN_MODAL_VISIBLE =
  'RENAME_CUSTOM_PLAN_MODAL_VISIBLE'
export const DELETE_CUSTOM_PLAN_MODAL_VISIBLE =
  'DELETE_CUSTOM_PLAN_MODAL_VISIBLE'
export const UPDATE_WEEKLY_PLAN_ID_IN_DROPDOWN =
  'UPDATE_WEEKLY_PLAN_ID_IN_DROPDOWN'
export const SET_SWAP_ACTION = 'SET_SWAP_ACTION'
export const ADD_DASHBOARD_PLAN_LOADING_ITEM = 'ADD_DASHBOARD_PLAN_LOADING_ITEM'
export const REMOVE_DASHBOARD_PLAN_LOADING_ITEM =
  'REMOVE_DASHBOARD_PLAN_LOADING_ITEM'
export const SET_SHOW_TRIAL_BANNER = 'SET_SHOW_TRIAL_BANNER'
export const SET_LAST_UNDO_ACTION = 'SET_LAST_UNDO_ACTION'
export const SET_MEAL_PLAN_TYPE_NAME = 'SET_MEAL_PLAN_TYPE_NAME'
// user
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const SET_USER_DATA = 'SET_USER_DATA'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const ADD_MESSAGES = 'ADD_MESSAGES'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const SET_USER_DATA_INIT_PROMISE = 'SET_USER_DATA_INIT_PROMISE'
export const SET_INTERCOM_USER_ATTRIBUTES = 'SET_INTERCOM_USER_ATTRIBUTES'
export const RESET_INTERCOM_USER = 'RESET_INTERCOM_USER'
export const SET_PLAN = 'SET_PLAN'
export const SET_USER_PHOTO = 'SET_USER_PHOTO'
export const SET_USER_NAME = 'SET_USER_NAME'
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_PREFERENCES = 'SET_PREFERENCES'
export const SET_ONBOARDING = 'SET_ONBOARDING'
export const SET_PREFERENCE = 'SET_PREFERENCE'
export const SET_PRINTING_OPTION = 'SET_PRINTING_OPTION'
export const SET_FACEBOOK_ID = 'SET_FACEBOOK_ID'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_NOTIFICATION_STATE = 'SET_NOTIFICATION_STATE'
export const SET_DASHBOARD_LATEST = 'SET_DASHBOARD_LATEST'
export const SET_SHARE_AND_EARN_CLICKED = 'SET_SHARE_AND_EARN_CLICKED'
export const SET_CAN_SHOW_UPSELL_PROMO_MODAL = 'SET_CAN_SHOW_UPSELL_PROMO_MODAL'
export const SET_SHOW_UPSELL_PROMO_MODAL = 'SET_SHOW_UPSELL_PROMO_MODAL'
export const SET_PROMOTION_INFO = 'SET_PROMOTION_INFO'
export const SET_USE_AMAZON_FRESH = 'SET_USE_AMAZON_FRESH'
export const SET_USE_NUTRITIONAL_TRACKING = 'SET_USE_NUTRITIONAL_TRACKING'
export const SET_USE_METRIC_UNITS = 'SET_USE_METRIC_UNITS'
export const SET_USE_TEMPERATURE_CELSIUS = 'SET_USE_TEMPERATURE_CELSIUS'
export const SET_SHARE_AND_EARN_MOBILE_BANNER_VISIBLE =
  'SET_SHARE_AND_EARN_MOBILE_BANNER_VISIBLE'

// user settings
export const SET_BILLING = 'SET_BILLING'
export const SET_BILLING_LOADING = 'SET_BILLING_LOADING'

// affiliate
export const SET_AFFILIATE_INFO = 'SET_AFFILIATE_INFO'
export const SET_COMMISSIONS_DATE = 'SET_COMMISSIONS_DATE'
export const SET_AFFILIATE_COMMISSIONS = 'SET_AFFILIATE_COMMISSIONS'

// recipe
export const SET_RECIPE_FAVORITE = 'SET_RECIPE_FAVORITE'
export const SET_RECIPE_RATING = 'SET_RECIPE_RATING'
export const SET_RECIPE_DETAILS = 'SET_RECIPE_DETAILS'
export const SET_CATEGORY_LIST = generateAsyncStatus('SET_CATEGORY_LIST')
export const SET_UNIT_LIST = generateAsyncStatus('SET_UNIT_LIST')
export const SET_INGREDIENT_LIST = generateAsyncStatus('SET_INGREDIENT_LIST')
export const SET_PREP_DESCRIPTION_LIST = generateAsyncStatus(
  'SET_PREP_DESCRIPTION_LIST'
)
export const SET_CREATE_PUBLIC_RECIPE = generateAsyncStatus(
  'SET_CREATE_PUBLIC_RECIPE'
)
export const SET_COPIED_URL = 'SET_COPIED_URL'
// globals
export const SET_LOADING = 'SET_LOADING'
export const SET_MEAL_PLAN_DATA = 'SET_MEAL_PLAN_DATA'
export const SET_MEAL_PLAN = 'SET_MEAL_PLAN'
export const SET_USER_WEEKLY_PLAN_ID = 'SET_USER_WEEKLY_PLAN_ID'
export const SET_PREVIEW_PARAMS = 'SET_PREVIEW_PARAMS'
export const SET_MEAL_PLANNER_LOCKED = 'SET_MEAL_PLANNER_LOCKED'
export const SET_CAMPAIGN = 'SET_CAMPAIGN'
export const SET_AFFILIATE_CODE = 'SET_AFFILIATE_CODE'
export const SET_GIFT_CODE = 'SET_GIFT_CODE'
export const SET_AFFILIATE_OFFER_DISCOUNT = 'SET_AFFILIATE_OFFER_DISCOUNT'
export const SET_AFFILIATE_OFFER_EXPIRES = 'SET_AFFILIATE_OFFER_EXPIRES'
export const SET_AFFILIATE_OFFER_BANNER = 'SET_AFFILIATE_OFFER_BANNER'
export const SET_ALWAYS_DISPLAY_AFFILIATE_OFFER_BANNER =
  'SET_ALWAYS_DISPLAY_AFFILIATE_OFFER_BANNER'
export const SET_AB_GROUP = 'SET_AB_GROUP'
export const SET_IMPACT_PARTNER_ID = 'SET_IMPACT_PARTNER_ID'
export const SET_AMBASSADOR_ID = 'SET_AMBASSADOR_ID'
export const SET_PREVIOUS_ROUTE = 'SET_PREVIOUS_ROUTE'
export const SET_SHOW_MEAL_PLAN_CHANGED_MODAL =
  'SET_SHOW_MEAL_PLAN_CHANGED_MODAL'
export const SET_SHOW_VERSION_UPDATED_MODAL = 'SET_SHOW_VERSION_UPDATED_MODAL'
export const SET_SHOW_USER_SUBSCRIBED_MODAL = 'SET_SHOW_USER_SUBSCRIBED_MODAL'
export const SET_SHOW_PRINT_MODAL = 'SET_SHOW_PRINT_MODAL'
export const SET_SHOW_DOWNLOAD_MODAL = 'SET_SHOW_DOWNLOAD_MODAL'
export const SET_PRINT_OPTIONS = 'SET_PRINT_OPTIONS'
export const SET_PROMOTION_BANNER_VISIBLE = 'SET_PROMOTION_BANNER_VISIBLE'
export const SET_PAYMENT_LATE_BANNER_VISIBLE = 'SET_PAYMENT_LATE_BANNER_VISIBLE'
export const SET_SUBSCRIPTION_REACTIVATE_BANNER_VISIBLE =
  'SET_SUBSCRIPTION_REACTIVATE_BANNER_VISIBLE'
export const SET_SUBSCRIPTION_EXPIRES_SOON_BANNER_VISIBLE =
  'SET_SUBSCRIPTION_EXPIRES_SOON_BANNER_VISIBLE'
export const SET_ALERT_MEMBER_BANNER_VISIBLE = 'SET_ALERT_MEMBER_BANNER_VISIBLE'
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR'
export const SET_CONNECTION_ERROR = 'SET_CONNECTION_ERROR'
export const SET_LOGIN_URL = 'SET_LOGIN_URL'
export const SET_BONUS_RECIPE_ID = 'SET_BONUS_RECIPE_ID'
export const SET_BONUS_RECIPE_NAME = 'SET_BONUS_RECIPE_NAME'
export const SET_TRIAL_BANNER_SHOWN = 'SET_TRIAL_BANNER_SHOWN'
export const SET_LONG_WAIT_TEXT = 'SET_LONG_WAIT_TEXT'
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
export const SET_NOPLAN_ERROR = 'SET_NOPLAN_ERROR'

// add custom recipe
export const UPDATE_USER_GENERATED_RECIPE = 'UPDATE_USER_GENERATED_RECIPE'
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA'
export const SET_ADD_CUSTOM_RECIPE_STATUS_LOADING =
  'SET_ADD_CUSTOM_RECIPE_STATUS_LOADING'
export const CLEAR_ADD_CUSTOM_RECIPE = 'CLEAR_ADD_CUSTOM_RECIPE'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY'
export const SET_CONFIRM_MODAL_VISIBILITY = 'SET_CONFIRM_MODAL_VISIBILITY'
export const SET_DATA_CHANGED = 'SET_DATA_CHANGED'
export const SET_STEP = 'SET_STEP'

export const SET_RECIPE_INGREDIENT_LIST_FROM_BE =
  'SET_RECIPE_INGREDIENT_LIST_FROM_BE'
export const SET_RECIPE_INGREDIENT_LIST = 'SET_RECIPE_INGREDIENT_LIST'
export const ADD_RECIPE_INGREDIENT = 'ADD_RECIPE_INGREDIENT'
export const EDIT_RECIPE_INGREDIENT = 'EDIT_RECIPE_INGREDIENT'
export const REMOVE_RECIPE_INGREDIENT = 'REMOVE_RECIPE_INGREDIENT'

// signup
export const ADULT_SERVINGS = 'ADULT_SERVINGS'
export const CHILD_SERVINGS = 'CHILD_SERVINGS'
export const HAS_INTOLERANCES = 'HAS_INTOLERANCES'
export const SOY_INTOLERANCE = 'SOY_INTOLERANCE'
export const NUTS_INTOLERANCE = 'NUTS_INTOLERANCE'
export const GLUTEN_INTOLERANCE = 'GLUTEN_INTOLERANCE'
export const ADDITIONAL_INTOLERANCES = 'ADDITIONAL_INTOLERANCES'
export const BREAKFAST_OPTIN = 'BREAKFAST_OPTIN'
export const LUNCH_OPTIN = 'LUNCH_OPTIN'
export const DINNER_OPTIN = 'DINNER_OPTIN'
export const SNACK_OPTIN = 'SNACK_OPTIN'
export const DESSERT_OPTIN = 'DESSERT_OPTIN'
export const PRESSURE_COOKER_OPTIN = 'PRESSURE_COOKER_OPTIN'
export const USER_EMAIL = 'USER_EMAIL'
export const USER_FULL_NAME = 'USER_FULL_NAME'
export const USER_PASSWORD = 'USER_PASSWORD'
export const USER_GENERAL_EMAILS_OPTIN = 'USER_GENERAL_EMAILS_OPTIN'
export const CURRENT_REGISTRATION_STEP = 'CURRENT_REGISTRATION_STEP'
export const CURRENT_REVERSE_REGISTRATION_STEP =
  'CURRENT_REVERSE_REGISTRATION_STEP'
export const CURRENT_PAYWALL_REGISTRATION_STEP =
  'CURRENT_PAYWALL_REGISTRATION_STEP'
export const REGISTRATION_STEP_DONE = 'REGISTRATION_STEP_DONE'
export const REVERSE_REGISTRATION_STEP_DONE = 'REVERSE_REGISTRATION_STEP_DONE'
export const PAYWALL_REGISTRATION_STEP_DONE = 'PAYWALL_REGISTRATION_STEP_DONE'
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR'
export const SET_REGISTRATION_STEPS = 'SET_REGISTRATION_STEPS'
export const SET_USER_GOALS = 'SET_USER_GOALS'
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO'
export const SET_REGISTRATION_TYPE = 'SET_REGISTRATION_TYPE'
export const SET_FACEBOOK_USER_INFO = 'SET_FACEBOOK_USER_INFO'
export const SET_GOOGLE_USER_INFO = 'SET_GOOGLE_USER_INFO'
export const SET_APPLE_USER_INFO = 'SET_APPLE_USER_INFO'

// headers
export const SET_USER_STATE = 'SET_USER_STATE'
export const SET_PLAN_CHANGE = 'SET_PLAN_CHANGE'
export const SET_USER_EXPIRED = 'SET_USER_EXPIRED'
export const SET_EXPIRES_IN = 'SET_EXPIRES_IN'
export const SET_EXTENDED_TRIAL = 'SET_EXTENDED_TRIAL'
export const SET_RELEASE_VERSION = 'SET_RELEASE_VERSION'
export const SET_PAYMENT_LATE = 'SET_PAYMENT_LATE'
export const SET_MESSAGES_COUNT = 'SET_MESSAGES_COUNT'
export const SET_CREDIT_CARD_REQUIRED = 'SET_CREDIT_CARD_REQUIRED'
export const SET_EXTEND_TRIAL_TOKEN = 'SET_EXTEND_TRIAL_TOKEN'
export const SET_EXTEND_TRIAL_MESSAGE = 'SET_EXTEND_TRIAL_MESSAGE'

// Weekend prep mutation types
export const SET_WEEKEND_PREP_DATA = 'SET_WEEKEND_PREP_DATA'
export const CLEAR_WEEKEND_PREP_DATA = 'CLEAR_WEEKEND_PREP_DATA'
export const WEEKEND_PREP_DATA_LOADING = 'WEEKEND_PREP_DATA_LOADING'
export const SET_WEEKEND_PREP_NOTE = 'SET_WEEKEND_PREP_NOTE'
export const SET_SPEED_PREP_DATA = 'SET_SPEED_PREP_DATA'

// Disqus
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_DISQUS_USER = 'SET_DISQUS_USER'
export const SET_DISQUS_THREAD_TITLE = 'SET_DISQUS_THREAD_TITLE'
export const SET_DISQUS_THREAD_IDENTIFIER = 'SET_DISQUS_THREAD_IDENTIFIER'
export const SET_DISQUS_THREAD_ID = 'SET_DISQUS_THREAD_ID'
export const SET_DISQUS_RAW_POSTS = 'SET_DISQUS_RAW_POSTS'
export const CLEAR_DISQUS_POSTS = 'CLEAR_DISQUS_POSTS'
export const CLEAR_DISQUS_RAW_POSTS = 'CLEAR_DISQUS_RAW_POSTS'
export const STRUCTURE_DISQUS_RAW_POSTS = 'STRUCTURE_DISQUS_RAW_POSTS'
export const ORDER_POSTS_BY_POPULARITY = 'ORDER_POSTS_BY_POPULARITY'
export const ADD_DISQUS_POST = 'ADD_DISQUS_POST'
export const EDIT_DISQUS_POST = 'EDIT_DISQUS_POST'
export const DELETE_DISQUS_POST = 'DELETE_DISQUS_POST'

// Disqus sidebar
export const SET_SIDE_BAR_VISIBILITY = 'SET_SIDE_BAR_VISIBILITY'
export const CLEAR_SIDE_BAR_POSTS = 'CLEAR_SIDE_BAR_POSTS'
export const CLEAR_SIDE_BAR = 'CLEAR_SIDE_BAR'
export const SET_SIDE_BAR_POSTS_USER = 'SET_SIDE_BAR_POSTS_USER'
export const SET_SIDE_BAR_PAGE = 'SET_SIDE_BAR_PAGE'
export const ADD_SIDE_BAR_POSTS = 'ADD_SIDE_BAR_POSTS'
export const EDIT_SIDE_BAR_POST = 'EDIT_SIDE_BAR_POST'
export const DELETE_SIDE_BAR_POST = 'DELETE_SIDE_BAR_POST'
export const VOTE_SIDE_BAR_POST = 'VOTE_SIDE_BAR_POST'
export const REPORT_SIDE_BAR_POST = 'REPORT_SIDE_BAR_POST'
export const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const SET_LAST_RECIPE_ID = 'SET_LAST_RECIPE_ID'

// Notifications
export const SET_VISIBLE_NOTIFICATION = 'SET_VISIBLE_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const SET_ICON_NOTIFICATION = 'SET_ICON_NOTIFICATION'
export const SET_TEXT_NOTIFICATION = 'SET_TEXT_NOTIFICATION'
export const SET_HTML_NOTIFICATION = 'SET_HTML_NOTIFICATION'
export const SET_CLOSE_NOTIFICATION = 'SET_CLOSE_NOTIFICATION'
export const SET_ACTION_NOTIFICATION = 'SET_ACTION_NOTIFICATION'
export const SET_NOTIFICATION_TIMEOUT = 'SET_NOTIFICATION_TIMEOUT'
export const CLEAR_NOTIFICATION_TIMEOUT = 'CLEAR_NOTIFICATION_TIMEOUT'
export const SET_COLOR_NOTIFICATION = 'SET_COLOR_NOTIFICATION'
export const SET_IS_FULL = 'SET_IS_FULL'
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'

// checkout
export const SET_CHECKOUT_PLANS = 'SET_CHECKOUT_PLANS'
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN'
export const SET_CHECKOUT_RIBBON = 'SET_RIBBON'
export const SET_CHECKOUT_TITLE = 'SET_CHECKOUT_TITLE'
export const SET_CHECKOUT_SUBTITLE = 'SET_CHECKOUT_SUBTITLE'
export const SET_CHECKOUT_CTA = 'SET_CHECKOUT_CTA'
export const SET_BADGE_COLOR = 'SET_BADGE_COLOR'
export const SET_TITLE_COLOR = 'SET_TITLE_COLOR'

// portal session
export const SET_PORTAL_SESISON = 'SET_PORTAL_SESISON'

// cancel flow
export const SET_CANCEL_URL = 'SET_CANCEL_URL'
