export const EVENTS = {
  registration: {
    step1: 'registration.step1',
    step2: 'registration.step2',
    step3: 'registration.step3',
    step4: 'registration.step4',
    step5: 'registration.step5',
    step6: 'registration.step6',
    skip: 'registration.skip',
    createAccount: 'registration.createAccount',
  },
  subscription: {
    create: 'subscription.create',
    renew: 'subscription.renew',
    initCancel: 'subscripiton.iniCancel',
  },
}
