import camelizeKeys from './camelizeKeys'
import api from './index'

const GET_PLANS = 'chargebee/configuration'
const CREATE_HOSTED = 'chargebee/checkout/create'
const UPDATE_HOSTED = 'chargebee/checkout/update'
const CREATE_SUBSCRIPTION = 'chargebee/checkout/process'
const CREATE_PORTAL = 'chargebee/portal-session'
const CREATE_CANCEL = 'chargebee/checkout/pre-cancel'

export default {
  getCheckout: (id) => {
    const options = {}

    if (id) {
      options.config_id = id
    }

    return api.get(GET_PLANS, options).then((res) => camelizeKeys(res.data))
  },
  createHostedPage: (params) =>
    api.post(CREATE_HOSTED, params).then((res) => res.data),
  updateHostedPage: (params) =>
    api.post(UPDATE_HOSTED, params).then((res) => res.data),
  createSubscriptionUser: (params) => {
    return api
      .post(CREATE_SUBSCRIPTION, params)
      .then((res) => camelizeKeys(res.data))
  },
  createPortal: () => api.post(CREATE_PORTAL).then((res) => res.data),
  createCancel: () => api.post(CREATE_CANCEL).then((res) => res.data),
}
