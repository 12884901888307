<template>
  <div id="settings-billing">
    <!-- delete account model -->
    <MPModal
      :show.sync="showDeleteAccountModal"
      :showX="true"
      class="account-deletion-modal"
    >
      <DeleteAccount @delete-user="deleteUser" />
    </MPModal>

    <MPModal :show.sync="showThankYouForTryingModal" :showX="true">
      <ThankYouForTrying @close="closeThankYouForTrying" />
    </MPModal>

    <!-- update payment -->
    <UpdatePaymentModal :show.sync="showUpdatePaymentModal" />

    <div
      v-if="isBillingLoading"
      class="d-flex justify-content-center mt-4 mt-md-0"
    >
      <MPSpinner :withOverlay="false"></MPSpinner>
    </div>
    <div v-else>
      <!-- Your Plan -->
      <SettingCard v-if="billing.onChargebee" type="rows">
        <template slot="label"> Your Plan </template>
        <template slot="content">
          <span class="the-plan-title">
            {{ billing.planTitle }}
          </span>
          <span class="description">
            {{ billing.planExternalName }}
          </span>
          <strong v-if="billing.onTrial">(In Trial)</strong>
        </template>
        <template slot="actions-content">
          <MPButton
            class="action-button btn btn-link"
            @click="changeSubscription()"
          >
            <Edit /> edit
          </MPButton>
        </template>
      </SettingCard>
      <SettingCard v-else type="rows">
        <template slot="label"> Your Plan </template>
        <template slot="content">
          <span class="the-plan-title"> Free plan </span>
        </template>
        <template slot="actions-content">
          <MPButton class="btn btn-outline-success" @click="goToUpgrade()">
            Upgrade
          </MPButton>
        </template>
      </SettingCard>
      <!-- Schedule -->
      <SettingCard type="rows" v-if="billing.onChargebee">
        <template slot="label"> Schedule </template>
        <template slot="content">
          <span class="description">
            {{ scheduleText }}
          </span>
        </template>
        <template slot="actions-content" v-if="billing.isCancelled">
          <MPButton
            class="btn btn-outline-success"
            @click="changeSubscription()"
          >
            Reactivate
          </MPButton>
        </template>
      </SettingCard>

      <!-- Card Info -->
      <!-- This is not needed by now -->
      <!-- <SettingCard type="rows">
        <template slot="label">
          Card Info
        </template>
        <template slot="content">
          <div class="card-info-wrapper">
            <img
              :src="require('@/assets/icons/cc-form-card-billing-tab.svg')"
            />
            <span class="card-info">
              <span class="mr-3">{{ billing.cardLast4 }}</span>
              <span>
                {{ billing.cardExpiryMonth }}/{{ billing.cardExpiryYear }}
              </span>
            </span>
          </div>
        </template>
        <template slot="actions-content">
          <MPButton
            class="action-button btn btn-link"
            @click="changePaymentMethod()"
          >
            <Edit /> Edit
          </MPButton>
        </template>
      </SettingCard> -->

      <!-- Cancel subscription -->
      <SettingCard type="rows-last" v-if="showCancel">
        <template slot="label"> Cancel </template>
        <template slot="content">
          <span class="description"> Want to cancel your subscription? </span>
        </template>
        <template slot="actions-content">
          <a :href="cancelUrl">
            <MPButton @click="openRetention" class="btn btn-outline-danger">
              Cancel
            </MPButton>
          </a>
        </template>
      </SettingCard>

      <!-- Delete account -->
      <SettingCard type="rows-last" v-else-if="showDelete">
        <template slot="label"> Delete </template>
        <template slot="content">
          <span class="description"> Permanently remove your account. </span>
        </template>
        <template slot="actions-content">
          <MPButton
            class="delete btn btn-outline-danger"
            @click="showDeleteAccountModal = true"
          >
            Delete
          </MPButton>
        </template>
      </SettingCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingCard from './SettingCard'
import DeleteAccount from './DeleteAccount'
import ThankYouForTrying from './ThankYouForTrying'
import UpdatePaymentModal from './UpdatePaymentModal'
import LoadingMixin from '@/mixins/LoadingMixin'
import MPSpinner from '@/components/common/MPSpinner'
import { Edit } from '@/components/common/MPIcons'
import ProfitWell from '@/externalServices/ProfitWell'
import ChargebeeMixin from '@/mixins/ChargebeeMixin'
import { EVENTS } from '@/events/analytics'
import moment from 'moment'

export default {
  name: 'SettingsBilling',
  mixins: [LoadingMixin, ChargebeeMixin],
  components: {
    MPSpinner,
    SettingCard,
    DeleteAccount,
    ThankYouForTrying,
    UpdatePaymentModal,
    Edit,
  },
  data() {
    return {
      showDeleteAccountModal: false,
    }
  },
  computed: {
    ...mapGetters({
      billing: 'user/billing',
      isBillingLoading: 'user/isBillingLoading',
      userIsCancelled: 'user/isCancelled',
      userIsExpired: 'user/isExpired',
      cancelUrl: 'user/cancelUrl',
    }),
    showDelete() {
      return !this.billing.onChargebee || this.userIsExpired
    },
    showCancel() {
      return !this.billing.isCancelled && this.billing.onChargebee
    },
    getNonEditablePlanText() {
      if (this.billing.processorType === 'google') {
        return 'You subscribed to Forks Meal Planner from an Android device. Please access the service on that device to manage your subscription.'
      } else if (this.billing.processorType === 'apple') {
        return 'You subscribed to Forks Meal Planner from an Apple device. You can use iTunes to manage your subscription.'
      }
      return ''
    },
    formattedDate() {
      return moment(this.billing.nextBillingDate).format('MMMM DD, YYYY')
    },
    scheduleText() {
      if (this.billing.isCancelled && this.billing.onTrial) {
        return `You will lose access to Forks Meal Planner on ${this.formattedDate} and your credit card will not be charged. Reactivate your subscription if you wish to keep using Forks Meal Planner`
      } else if (this.billing.isCancelled) {
        return `You will lose access to Forks Meal Planner on ${this.formattedDate}`
      } else {
        return `Your ${
          this.billing.onTrial ? 'first' : 'next'
        } payment of ${this.intToMoney(this.billing.nextInvoicePrice)} USD
            will be debited on
            ${this.formattedDate}`
      }
    },
  },
  methods: {
    async cancelSubscriptionClicked() {
      this.$analytics.track('mp settings cancel account clicked')
      try {
        const result = await ProfitWell.cancel(this.billing.subscriptionId)
        if (result) {
          this.cancelPlan(result)
        }
      } catch (err) {
        console.error(err)
      }
    },
    goToUpgrade() {
      this.$router.push('/reactivate')
    },
    cancelPlan({ reason, ...moreInfo }) {
      let reasonForCancelling = reason
      this.setLoading(true)
      return this.$store.dispatch('user/cancelPlan').then(() => {
        this.$store.dispatch('user/setIntercomUserAttributes', {
          reason_for_cancelling: reasonForCancelling,
          ...moreInfo,
        })
        this.$intercom.trackEvent('cancelled')
        this.$analytics.track('mp settings cancel account confirmed', {
          'reason cancelled': reasonForCancelling,
          ...moreInfo,
        })
        this.setLoading(false)
      })
    },
    deleteUser() {
      this.setLoading(true)
      return this.$store.dispatch('user/deleteUser').then(
        () => {
          this.showDeleteAccountModal = false
          this.setLoading(false)
          this.showThankYouForTryingModal = true
        },
        () => {
          this.setLoading(false)
        }
      )
    },
    closeThankYouForTrying() {
      location.reload()
    },
    intToMoney(quantity) {
      const stringNumber = quantity.toString()
      const numberWithoutDecimals = stringNumber.substring(
        0,
        stringNumber.length - 2
      )
      const decimals = stringNumber.substring(stringNumber.length - 2)
      const moneyFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(parseFloat(numberWithoutDecimals + '.' + decimals))

      return moneyFormat
    },
    openRetention() {
      this.$posthog.sendEvent(EVENTS.subscription.initCancel)
    },
  },
  mounted() {
    // open credit card modal if parameter is given
    this.showUpdatePaymentModal =
      this.$route.query['openModal'] === 'updateCardInfo'
    this.$analytics.track('mp settings billing view')
  },
}
</script>

<style lang="scss">
.special-offer-modal {
  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: 600px;
    }
  }

  .mp-modal-content {
    background-color: $white;
    @include media-breakpoint-up(md) {
      padding: 2rem;
    }
  }
}
.account-deletion-modal {
  .mp-modal-content {
    padding: 30px;
    @include media-breakpoint-down(sm) {
      padding: 15px;
      padding-top: 20px;
    }
  }
  .x-icon {
    @include media-breakpoint-down(sm) {
      top: 15px;
    }
  }
}
</style>

<style lang="scss" scoped>
.delete {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 1.125rem;
  line-height: 1.5;
}

.plan-not-editable {
  background-color: $clr-navy-5;
  padding: 1rem;
  text-align: left;
  color: $clr-navy-70;
  font-weight: 500;
}

.the-plan-title {
  font-weight: 500;
  display: block;
}

.card-info-wrapper {
  display: flex;
  align-items: center;
}

.card-info {
  color: $clr-navy-70;
  font-size: 0.875rem;
  padding-left: 0.5rem;
}

.plan-upsell {
  display: block;
  color: $clr-navy-70;
  font-size: 0.875rem;
}

.cancel-plan-title {
  font-size: 20px;
  font-weight: bold;
}

.button-upcase {
  text-transform: uppercase;
}

.action-button {
  padding: 0;
  text-align: right;
  svg {
    width: 28px;
    height: auto;
    vertical-align: bottom;
  }
}
</style>
