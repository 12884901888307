import * as types from './../mutation-types'

// initial state
const state = {
  loading: false,
  longWaitText: null,
  mealPlan: null,
  userWeeklyPlanId: null,
  previewParams: null,
  isMealPlannerLocked: false,
  campaign: null,
  affiliateCode: null,
  giftCode: null,
  abGroup: null,
  impactPartnerId: null,
  ambassadorId: null,
  previousRoute: null,
  showMealPlanChangeModal: false,
  showVersionUpdatedModal: false,
  showUserSubscribedModal: false,
  showPrintModal: false,
  showDownloadModal: false,
  printOptions: {
    plan: false,
    groceryList: false,
    prep: false,
  },
  affiliateOfferDiscount: null,
  affiliateOfferExpires: null,
  affiliateOfferBanner: null,
  alwaysDisplayOfferBanner: false,
  promotionBannerVisible: false, // changed to true when promotion should be shown
  paymentLateBannerVisible: true, // default is true, with additional condition that headers.paymentLate is set
  subscriptionReactivateBannerVisible: true, // default show, with additional condition
  subscriptionExpiresSoonBannerVisible: true,
  alertMemberBannerVisible: false,
  serverError: null,
  connectionError: null,
  loginURL: '/login',
  bonusRecipeId: null,
  bonusRecipeName: null,
  trialBannerShown: false,
  toastMessage: null,
  noPlanError: null,
}

// getters
const getters = {
  trialBannerShown: (state) => state.trialBannerShown,
  loading: (state) => state.loading,
  longWaitText: (state) => state.longWaitText,
  mealPlan: (state) => state.mealPlan,
  userWeeklyPlanId: (state) => state.userWeeklyPlanId,
  previewParams: (state) => state.previewParams,
  isMealPlannerLocked: (state) => state.isMealPlannerLocked,
  campaign: (state) => state.campaign,
  affiliateCode: (state) => state.affiliateCode,
  giftCode: (state) => state.giftCode,
  abGroup: (state) => state.abGroup,
  impactPartnerId: (state) => state.impactPartnerId,
  ambassadorId: (state) => state.ambassadorId,
  previousRoute: (state) => state.previousRoute,
  showMealPlanChangeModal: (state) => state.showMealPlanChangeModal,
  showVersionUpdatedModal: (state) => state.showVersionUpdatedModal,
  showUserSubscribedModal: (state) => state.showUserSubscribedModal,
  showPrintModal: (state) => state.showPrintModal,
  showDownloadModal: (state) => state.showDownloadModal,
  printOptions: (state) => state.printOptions,
  affiliateOfferDiscount: (state) => state.affiliateOfferDiscount,
  affiliateOfferExpires: (state) => state.affiliateOfferExpires,
  affiliateOfferBanner: (state) => state.affiliateOfferBanner,
  alwaysDisplayOfferBanner: (state) => state.alwaysDisplayOfferBanner,
  promotionBannerVisible: (state) => state.promotionBannerVisible,
  paymentLateBannerVisible: (state) => state.paymentLateBannerVisible,
  subscriptionReactivateBannerVisible: (state) =>
    state.subscriptionReactivateBannerVisible,
  subscriptionExpiresSoonBannerVisible: (state) =>
    state.subscriptionExpiresSoonBannerVisible,
  alertMemberBannerVisible: (state) => state.alertMemberBannerVisible,
  serverError: (state) => state.serverError,
  connectionError: (state) => state.connectionError,
  loginURL: (state) => state.loginURL,
  bonusRecipeId: (state) => state.bonusRecipeId,
  bonusRecipeName: (state) => state.bonusRecipeName,
  promotedRecipesNumber(state, getters, rootState, rootGetters) {
    const { 'RecipeBox/numberOfAllPlannerRecipes': recipesNumber } = rootGetters
    return Math.floor((recipesNumber || 1000) / 100) * 100
  },
  toastMessage: (state) => state.toastMessage,
  noPlanError: (state) => state.noPlanError,
}

let toastMessageTimeout

// actions
const actions = {
  showMealPlanChangeModal({ commit }) {
    commit(types.SET_SHOW_MEAL_PLAN_CHANGED_MODAL, true)
  },
  showVersionUpdatedModal({ commit }) {
    commit(types.SET_SHOW_VERSION_UPDATED_MODAL, true)
  },
  showUserSubscribedModal({ commit }) {
    commit(types.SET_SHOW_USER_SUBSCRIBED_MODAL, true)
  },
  showPrintModal({ commit }, show) {
    commit(types.SET_SHOW_PRINT_MODAL, show)
  },
  showDownloadModal({ commit }, show) {
    commit(types.SET_SHOW_DOWNLOAD_MODAL, show)
  },
  setPrintOptions({ commit }, options) {
    commit(types.SET_PRINT_OPTIONS, options)
  },
  setLoading({ commit }, isLoading) {
    commit(types.SET_LOADING, isLoading)
  },
  setIsMealPlannerLocked({ commit }, isLocked) {
    commit(types.SET_MEAL_PLANNER_LOCKED, isLocked)
  },
  setMealPlanData({ commit }, { mealPlan, userWeeklyPlanId, previewParams }) {
    commit(types.SET_MEAL_PLAN_DATA, {
      mealPlan,
      userWeeklyPlanId,
      previewParams,
    })
  },
  setMealPlan({ commit }, mealPlan) {
    commit(types.SET_MEAL_PLAN, mealPlan)
  },
  setUserWeeklyPlanId({ commit }, userWeeklyPlanId) {
    commit(types.SET_USER_WEEKLY_PLAN_ID, userWeeklyPlanId)
  },
  setPreviewParams({ commit }, previewParams) {
    commit(types.SET_PREVIEW_PARAMS, previewParams)
  },
  setMealPlannerLocked({ commit }, isLocked) {
    commit(types.SET_MEAL_PLANNER_LOCKED, isLocked)
  },
  setCampaign({ commit }, campaign) {
    commit(types.SET_CAMPAIGN, campaign)
  },
  setAffiliateCode({ commit }, affiliateCode) {
    commit(types.SET_AFFILIATE_CODE, affiliateCode)
  },
  setGiftCode({ commit }, giftCode) {
    commit(types.SET_GIFT_CODE, giftCode)
  },
  setABGroup({ commit }, abGroup) {
    commit(types.SET_AB_GROUP, abGroup)
  },
  setImpactPartnerId({ commit }, impactPartnerId) {
    commit(types.SET_IMPACT_PARTNER_ID, impactPartnerId)
  },
  setAmbassadorId({ commit }, ambassadorId) {
    commit(types.SET_AMBASSADOR_ID, ambassadorId)
  },
  setPreviousRoute({ commit }, previousRoute) {
    const route = Object.assign({}, previousRoute)
    commit(types.SET_PREVIOUS_ROUTE, route)
  },
  setAffiliateOfferDiscount({ commit }, discount) {
    commit(types.SET_AFFILIATE_OFFER_DISCOUNT, discount)
  },
  setAffiliateOfferExpires({ commit }, expires) {
    commit(types.SET_AFFILIATE_OFFER_EXPIRES, expires)
  },
  setAffiliateOfferBanner({ commit }, banner) {
    commit(types.SET_AFFILIATE_OFFER_BANNER, banner)
  },
  setAlwaysDisplayAffiliateOfferBanner({ commit }, alwaysDisplay) {
    commit(types.SET_ALWAYS_DISPLAY_AFFILIATE_OFFER_BANNER, alwaysDisplay)
  },
  setPromotionBannerVisible({ commit }, visible) {
    commit(types.SET_PROMOTION_BANNER_VISIBLE, visible)
  },
  setPaymentLateBannerVisible({ commit }, visible) {
    commit(types.SET_PAYMENT_LATE_BANNER_VISIBLE, visible)
  },
  setSubscriptionReactivateBannerVisible({ commit }, visible) {
    commit(types.SET_SUBSCRIPTION_REACTIVATE_BANNER_VISIBLE, visible)
  },
  setSubscriptionExpiresSoonBannerVisible({ commit }, visible) {
    commit(types.SET_SUBSCRIPTION_EXPIRES_SOON_BANNER_VISIBLE, visible)
  },
  setAlertMemberBannerVisible({ commit }, visible) {
    commit(types.SET_ALERT_MEMBER_BANNER_VISIBLE, visible)
  },
  setServerError({ commit }, error) {
    commit(types.SET_SERVER_ERROR, error)
  },
  setConnectionError({ commit }, error) {
    commit(types.SET_CONNECTION_ERROR, error)
  },
  setLoginURL({ commit }, url) {
    commit(types.SET_LOGIN_URL, url)
  },
  setBonusRecipeId({ commit }, bonusRecipeId) {
    commit(types.SET_BONUS_RECIPE_ID, bonusRecipeId)
  },
  setBonusRecipeName({ commit }, bonusRecipeName) {
    commit(types.SET_BONUS_RECIPE_NAME, bonusRecipeName)
  },
  setTrialBannerShown({ commit }, shown) {
    commit(types.SET_TRIAL_BANNER_SHOWN, shown)
  },
  setLongWaitText({ commit }, text) {
    commit(types.SET_LONG_WAIT_TEXT, text)
  },
  /**
   * @param {Object} message - Global Toast Message object.
   * @param {string} message.text - Message text.
   * @param {string} message.clearTimeout - Auto clear timeout time.
   */
  setToastMessage({ commit }, message) {
    clearTimeout(toastMessageTimeout)
    commit(types.SET_TOAST_MESSAGE, message)
    if (message.clearTimeout) {
      toastMessageTimeout = setTimeout(() => {
        commit(types.SET_TOAST_MESSAGE, null)
      }, message.clearTimeout)
    }
  },
  setNoPlanError({ commit }, message) {
    commit(types.SET_NOPLAN_ERROR, message)
  },
}

// mutations
const mutations = {
  [types.SET_LOADING](state, isLoading) {
    state.loading = isLoading
    if (!isLoading) {
      state.longWaitText = null
    }
  },
  [types.SET_MEAL_PLAN_DATA](state, payload) {
    state.mealPlan = payload.mealPlan
    state.userWeeklyPlanId = payload.userWeeklyPlanId
    state.previewParams = payload.previewParams
  },
  [types.SET_MEAL_PLAN](state, mealPlan) {
    state.mealPlan = mealPlan
  },
  [types.SET_USER_WEEKLY_PLAN_ID](state, userWeeklyPlanId) {
    state.userWeeklyPlanId = userWeeklyPlanId
  },
  [types.SET_PREVIEW_PARAMS](state, previewParams) {
    state.previewParams = previewParams
  },
  [types.SET_MEAL_PLANNER_LOCKED](state, isLocked) {
    state.isMealPlannerLocked = isLocked
  },
  [types.SET_CAMPAIGN](state, campaign) {
    state.campaign = campaign
  },
  [types.SET_AFFILIATE_CODE](state, affiliateCode) {
    state.affiliateCode = affiliateCode
  },
  [types.SET_GIFT_CODE](state, giftCode) {
    state.giftCode = giftCode
  },
  [types.SET_AB_GROUP](state, abGroup) {
    state.abGroup = abGroup
  },
  [types.SET_IMPACT_PARTNER_ID](state, impactPartnerId) {
    state.impactPartnerId = impactPartnerId
  },
  [types.SET_AMBASSADOR_ID](state, ambassadorId) {
    state.ambassadorId = ambassadorId
  },
  [types.SET_PREVIOUS_ROUTE](state, previousRoute) {
    state.previousRoute = previousRoute
  },
  [types.SET_SHOW_MEAL_PLAN_CHANGED_MODAL](state) {
    state.showMealPlanChangeModal = true
  },
  [types.SET_SHOW_VERSION_UPDATED_MODAL](state) {
    state.showVersionUpdatedModal = true
  },
  [types.SET_SHOW_USER_SUBSCRIBED_MODAL](state) {
    state.showUserSubscribedModal = true
  },
  [types.SET_SHOW_PRINT_MODAL](state, show) {
    state.showPrintModal = show
  },
  [types.SET_SHOW_DOWNLOAD_MODAL](state, show) {
    state.showDownloadModal = show
  },
  [types.SET_PRINT_OPTIONS](state, options) {
    state.printOptions = options
  },
  [types.SET_AFFILIATE_OFFER_DISCOUNT](state, discount) {
    state.affiliateOfferDiscount = discount
  },
  [types.SET_AFFILIATE_OFFER_EXPIRES](state, expires) {
    state.affiliateOfferExpires = expires
  },
  [types.SET_AFFILIATE_OFFER_BANNER](state, banner) {
    state.affiliateOfferBanner = banner
  },
  [types.SET_ALWAYS_DISPLAY_AFFILIATE_OFFER_BANNER](state, alwaysDisplay) {
    state.alwaysDisplayOfferBanner = alwaysDisplay
  },
  [types.SET_PROMOTION_BANNER_VISIBLE](state, visible) {
    state.promotionBannerVisible = visible
  },
  [types.SET_PAYMENT_LATE_BANNER_VISIBLE](state, visible) {
    state.paymentLateBannerVisible = visible
  },
  [types.SET_SUBSCRIPTION_REACTIVATE_BANNER_VISIBLE](state, visible) {
    state.subscriptionReactivateBannerVisible = visible
  },
  [types.SET_SUBSCRIPTION_EXPIRES_SOON_BANNER_VISIBLE](state, visible) {
    state.subscriptionExpiresSoonBannerVisible = visible
  },
  [types.SET_ALERT_MEMBER_BANNER_VISIBLE](state, visible) {
    state.alertMemberBannerVisible = visible
  },
  [types.SET_SERVER_ERROR](state, error) {
    state.serverError = error
  },
  [types.SET_CONNECTION_ERROR](state, error) {
    state.connectionError = error
  },
  [types.SET_LOGIN_URL](state, url) {
    state.loginURL = url
  },
  [types.SET_BONUS_RECIPE_ID](state, bonusRecipeId) {
    state.bonusRecipeId = bonusRecipeId
  },
  [types.SET_BONUS_RECIPE_NAME](state, bonusRecipeName) {
    state.bonusRecipeName = bonusRecipeName
  },
  [types.SET_TRIAL_BANNER_SHOWN](state, shown) {
    state.trialBannerShown = shown
  },
  [types.SET_LONG_WAIT_TEXT](state, text) {
    state.longWaitText = text
  },
  [types.SET_TOAST_MESSAGE](state, message) {
    state.toastMessage = message
  },
  [types.SET_NOPLAN_ERROR](state, message) {
    state.noPlanError = message
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
