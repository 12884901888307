<template>
  <div>
    <div class="plans-list">
      <PlanDropdown
        buttonText="Weekly"
        type="weekly"
        :showCalendarIcon="true"
        :isActive="isNormalPlanSelected"
      />
      <PlanDropdown
        buttonText="Custom"
        :isLocked="!hasCustomPlansPermissions() || isMealPlannerLocked"
        :isActive="isCustomPlanSelected"
        type="custom"
      /><PlanDropdown
        buttonText="Featured"
        :isActive="isPromotionalPlanSelected"
        type="Promotional"
      />
    </div>
    <h3 class="text-clr-navy text-center mt-4">
      {{ weeklyTabTitle }}
    </h3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlanDropdown from '@/components/navigation/PlanDropdown'
import { categories } from '../constants'
export default {
  components: {
    PlanDropdown,
  },
  computed: {
    ...mapGetters({
      mealPlan: 'globals/mealPlan',
      selectedItem: 'dashboard/mealPlanSelectedItem',
      mealPlanItems: 'dashboard/mealPlanItems',
      weeklyMealPlans: 'dashboard/mealPlanWeeklyItems',
      isFreeUser: 'user/isFree',
      permissions: 'user/permissions',
      isMealPlannerLocked: 'globals/isMealPlannerLocked',
    }),
    freePlan() {
      return this.weeklyMealPlans.find((p) => p.isSample)
    },
    thisWeekPlan() {
      return this.weeklyMealPlans.find((p) => p.isCurrent)
    },
    weeklyTabTitle() {
      return this.getPlanName(this.selectedItem)
    },
    isCustomPlanSelected() {
      return this.mealPlan == 'custom'
    },
    isPromotionalPlanSelected() {
      return this.mealPlanItems.some(
        (mp) =>
          mp.value == this.mealPlan && mp.category == categories.promotional
      )
    },
    isNormalPlanSelected() {
      return (
        ['current', 'sample'].includes(this.mealPlan) ||
        this.mealPlanItems.some(
          (mp) =>
            mp.value == this.mealPlan &&
            mp.category != categories.promotional &&
            this.mealPlan != 'custom'
        )
      )
    },
  },
  methods: {
    selectMealPlan({ value, userWeeklyPlanId }) {
      this.$root.$emit('bv::hide::popover')
      this.$store.dispatch(
        'globals/setMealPlanData',
        { mealPlan: value, userWeeklyPlanId: userWeeklyPlanId },
        { root: true }
      )
      this.$store.dispatch('dashboard/refreshDashboard')
      this.$router.go()
    },
    hasCustomPlansPermissions() {
      return this.permissions.canAccessCustomMealPlans != false
    },
    getPlanName(plan) {
      if (plan.isCurrent) {
        const date = parseInt(plan.shortSubTitle)
        return `Week of ${plan.shortTitle.substr(0, 3)} ${date}`
      }
      return plan.label
    },
  },
}
</script>

<style lang="scss" scoped>
.plans-list {
  display: flex;
}
.plans-list > * + * {
  margin-left: 30px;

  @include media-breakpoint-down(md) {
    margin-left: 15px;
  }
}

.plans-list-item {
  padding: 0;
  display: block;

  &.active,
  &:hover {
    span {
      border-bottom: solid 4px $link-color;
      color: $link-color;
    }
  }
}

.plan-icon {
  color: $link-color;
  margin-top: -4px;
}

.plans-list-item-label {
  text-transform: uppercase;
  padding-bottom: 8px;
  letter-spacing: 0.2px;
  &:hover {
    color: $link-hover-color;

    .plan-icon {
      color: $link-hover-color;
    }
  }
}
</style>
