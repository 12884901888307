import environment from '@/environment'
import apiCheckout from '@/api/checkout'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    chargebee: null,
    portal: null,
  }),
  mounted() {
    if (!this.chargebee) {
      this.chargebee = window.Chargebee.init({
        site: environment.chargebee.site,
        enableFBQTracking: true,
        enableGTMTracking: true,
        iframeOnly: true,
      })
    }
  },
  computed: {
    ...mapGetters({
      portalSession: 'user/portalSession',
      billing: 'user/billing',
    }),
  },
  methods: {
    ...mapActions({
      refreshBilling: 'user/refreshBilling',
    }),
    closeModals() {
      this.chargebee.closeAll()
    },
    openCheckout(
      cb,
      { onSuccess = () => {}, onError = () => {}, onClose = () => {} }
    ) {
      this.chargebee.openCheckout({
        hostedPage: async () => {
          return await cb()
        },
        success: (hostedPageId) => {
          this.chargebee.closeAll()
          onSuccess(hostedPageId)
        },
        error: (err) => onError(err),
        close: onClose(),
      })
    },
    newSubscription(data, { onSuccess, onError, onClose }) {
      this.openCheckout(() => apiCheckout.createHostedPage(data), {
        onSuccess,
        onError,
        onClose,
      })
    },
    updateSubscription(data, { onSuccess, onError, onClose }) {
      this.openCheckout(() => apiCheckout.updateHostedPage(data), {
        onSuccess,
        onError,
        onClose,
      })
    },
    async instancePortal() {
      this.chargebee.setPortalSession(() => {
        return apiCheckout.createPortal()
      })
    },
    async openPortalSection(type, params) {
      if (!this.portal) {
        this.portal = this.chargebee.createChargebeePortal()
      }
      await this.instancePortal()
      this.portal.openSection(
        {
          sectionType: type,
          params,
        },
        {
          close: () => {
            this.chargebee.logout()
          },
          subscriptionChanged: () => {
            this.refreshBilling()
          },
          paymentSourceAdd: () => {
            this.refreshBilling()
          },
          paymentSourceUpdate: () => {
            this.refreshBilling()
          },
          paymentSourceRemove: () => {
            this.refreshBilling()
          },
          scheduledCancellationRemoved: () => {
            this.refreshBilling()
          },
        }
      )
    },
    changeSubscription() {
      this.openPortalSection(
        window.Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
        {
          subscriptionId: this.billing.subscriptionId,
        }
      )
    },
    changePaymentMethod() {
      this.openPortalSection(
        window.Chargebee.getPortalSections().PAYMENT_SOURCES,
        {
          subscriptionId: this.billing.subscriptionId,
        }
      )
    },
  },
}
