<template>
  <div>
    <div class="promotion-wrapper" v-if="ribbon">
      <div
        class="promotion pt-1 pb-1 px-3"
        :style="{
          backgroundColor: ribbon.colors.background,
          color: ribbon.colors.text,
        }"
        v-html="ribbon.text"
      ></div>
    </div>
    <div class="checkout mt-3 mx-3">
      <div class="checkout-content">
        <h2 class="checkout-content-title">{{ title }}</h2>
        <div class="checkout-content-description" v-html="subtitle"></div>
        <div class="plans-wrapper mt-4 mb-2 mb-md-3">
          <div
            v-for="plan in plans"
            class="plan pb-1 pt-3 px-2 px-md-3"
            :class="{
              isSelected: selectedPlan && selectedPlan.id === plan.id,
            }"
            @click="changeSelected(plan)"
            :key="plan.id"
          >
            <div>
              <div class="plan-header">
                <h3 class="plan-header-title">{{ plan.title }}</h3>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`${plan.id}_checkbox`"
                    @click.prevent
                    :checked="selectedPlan && selectedPlan.id === plan.id"
                  />
                  <label
                    class="custom-control-label print-recipe-toggle-text"
                    :for="`${plan.id}_checkbox`"
                  >
                  </label>
                </div>
              </div>
              <div class="plan-body" v-html="plan.description"></div>
              <div
                v-if="plan.finePrint"
                class="plan-footprint"
                v-html="plan.finePrint"
              ></div>
              <div v-if="plan.showBadge" class="plan-badge py-1 px-3">
                <span>{{ plan.badgeContent }}</span>
              </div>
            </div>
          </div>
        </div>
        <p class="expectation m-0 pb-2 pb-md-3" v-html="parsedExpectation"></p>
        <MPButton
          @click="$emit('onCheckout')"
          type="button"
          color="primary"
          size="full-width"
          >{{ cta }}</MPButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MPButton from '@/components/common/MPButton.vue'
import { EVENTS } from '@/events/analytics'

export default {
  name: 'RegisterCheckout',
  computed: {
    ...mapGetters({
      plans: 'checkout/plans',
      selectedPlan: 'checkout/selectedPlan',
      ribbon: 'checkout/ribbon',
      title: 'checkout/title',
      subtitle: 'checkout/subtitle',
      cta: 'checkout/cta',
    }),
    parsedExpectation() {
      return this.selectedPlan && this.selectedPlan.expectation
        ? this.selectedPlan.expectation
        : ''
    },
  },
  methods: {
    ...mapActions({
      changeSelected: 'checkout/setSelectedPlan',
    }),
  },
  mounted() {
    this.$posthog.sendEvent(EVENTS.registration.step6)
  },
  components: [MPButton],
}
</script>

<style lang="scss" scoped>
.checkout {
  font-size: 1rem;

  &-content {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    text-align: center;
    &-title {
      font-size: 3em;
      text-transform: none;
      @include media-breakpoint-down(sm) {
        font-size: 2.5em;
      }
    }
  }
}

.promotion-wrapper {
  width: 100%;

  &::v-deep p {
    margin: 0 !important;
  }

  .promotion {
    text-align: center;
  }
}

.plans-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  row-gap: 1.5em;

  .plan {
    background-color: $body-bg;
    border-style: solid;
    border-width: 2px;
    border-color: $light-grey;
    border-radius: $card-border-radius;
    position: relative;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .custom-checkbox label::before,
      .custom-checkbox label::after {
        width: 2em;
        height: 2em;
      }

      &-title {
        font-size: 1.5em;
        text-transform: none;
      }
    }

    &-body {
      font-size: 0.8em;
      text-align: left;
      &::v-deep ul {
        padding-left: 1em;
      }
    }

    &-footprint {
      font-size: 0.7em;
      color: $text-gray;
      &::v-deep p {
        color: inherit;
        margin: 0;
        text-align: left;
      }
    }

    &-badge {
      background-color: $success;
      color: $white;
      position: absolute;
      top: -20px;
      left: 1em;
    }

    &.isSelected,
    &:hover {
      border-color: $success;
    }
  }
}

.expectation {
  text-align: left;
  color: $text-gray;
  &::v-deep p {
    margin: 0;
  }
}
</style>
@/events/analytics
