import api from '.'
import camelizeKeys from '@/api/camelizeKeys'
import environment from '@/environment'

const SHOPPING_LIST = 'shopping-list/'
const AMAZON_FRESH_ITEMS = 'amazon-fresh-items/'
const BY_AISLE = 'aisle-items/'
const BY_AISLE_PREVIEW = 'preview-aisle-items/'
const BY_RECIPE = 'recipe-items/'
const BY_RECIPE_PREVIEW = 'preview-recipe-items/'
const TIPS = 'tip-items/'
const TIPS_PREVIEW = 'preview-tip-items/'
const UNIT_LIST = 'recipes/unit-list/'
const AISLE_LIST = 'recipes/aisle-list/'

const REMOVE_AISLE_FROM_SHOPPING_LIST = 'remove-aisle-item-from-shopping-list/' // id and MealPlan
const ADD_AISLE_TO_SHOPPING_LIST = 'add-aisle-item-to-shopping-list/' // id and MealPlan
const REMOVE_RECIPE_ITEM_FROM_SHOPPING_LIST =
  'remove-recipe-item-from-shopping-list/'
const ADD_RECIPE_ITEM_TO_SHOPPING_LIST = 'add-recipe-item-to-shopping-list/'

const ADD_CUSTOM_SHOPPING_LIST_ITEM = 'recipes/add-custom-shopping-list-item/'
const REMOVE_CUSTOM_SHOPPING_LIST_ITEM =
  'recipes/remove-custom-shopping-list-item/'
const UPDATE_CUSTOM_SHOPPING_LIST_ITEM =
  'recipes/update-custom-shopping-list-item/'
const ADD_AVAILABLE_INGREDIENT = 'recipes/add-available-ingredient/'
const REMOVE_AVAILABLE_INGREDIENT = 'recipes/remove-available-ingredient/'
const UPDATE_AVAILABLE_INGREDIENT = 'recipes/update-available-ingredient/'
const PRINT_BY_AISLE_FILE = [SHOPPING_LIST, 'print/'].join('')
const PRINT_BY_RECIPE_FILE = [SHOPPING_LIST, 'print-by-recipe/'].join('')

export default {
  printFileByAisle(mealPlan, userWeeklyPlanId) {
    const params = {
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${environment.printBaseUrl}${PRINT_BY_AISLE_FILE}${mealPlan}/`
    return api.get(url, params, null, 'arraybuffer').then((res) => {
      return res.data
    })
  },
  printFileByRecipe(mealPlan, userWeeklyPlanId) {
    const params = {
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${environment.printBaseUrl}${PRINT_BY_RECIPE_FILE}${mealPlan}/`
    return api.get(url, params, null, 'arraybuffer').then((res) => {
      return res.data
    })
  },
  addAvailableIngredient(
    mealPlan,
    userWeeklyPlanId,
    ingredientId,
    quantity,
    unit_id
  ) {
    var payload = {
      meal_plan_id: mealPlan,
      user_weekly_plan_id: userWeeklyPlanId,
      ingredient_id: ingredientId,
      unit_id: unit_id,
      quantity: quantity,
    }
    return api.post(ADD_AVAILABLE_INGREDIENT, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  removeAvailableIngredient(mealPlan, userWeeklyPlanId, ingredientId) {
    var payload = {
      meal_plan_id: mealPlan,
      user_weekly_plan_id: userWeeklyPlanId,
      available_ingredient_id: ingredientId,
    }
    return api.post(REMOVE_AVAILABLE_INGREDIENT, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  updateAvailableIngredient(
    mealPlan,
    userWeeklyPlanId,
    ingredientId,
    quantity,
    unit_id
  ) {
    var payload = {
      meal_plan_id: mealPlan,
      user_weekly_plan_id: userWeeklyPlanId,
      available_ingredient_id: ingredientId,
      unit_id: unit_id,
      quantity: quantity,
    }
    return api.post(UPDATE_AVAILABLE_INGREDIENT, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  updateCustomShoppingListItem(
    mealPlan,
    userWeeklyPlanId,
    itemId,
    name,
    quantity,
    aisleId,
    excluded,
    unitId
  ) {
    var payload = {
      meal_plan_id: mealPlan,
      user_weekly_plan_id: userWeeklyPlanId,
      custom_shopping_list_item_id: itemId,
      name: name,
      quantity: quantity,
      aisle_id: aisleId,
      is_excluded: excluded,
      unit_id: unitId,
    }
    return api.post(UPDATE_CUSTOM_SHOPPING_LIST_ITEM, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  addRecipeItemToShoppingList(
    mealPlan,
    userWeeklyPlanId,
    ids,
    cids,
    recipeId,
    ingredientId
  ) {
    var payload = {
      ids: ids,
      cids: cids,
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${SHOPPING_LIST}${ADD_RECIPE_ITEM_TO_SHOPPING_LIST}${ingredientId}/${recipeId}/${mealPlan}/`
    return api.post(url, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  removeRecipeItemFromShoppingList(
    mealPlan,
    userWeeklyPlanId,
    ids,
    cids,
    recipeId,
    ingredientId
  ) {
    var payload = {
      ids: ids,
      cids: cids,
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${SHOPPING_LIST}${REMOVE_RECIPE_ITEM_FROM_SHOPPING_LIST}${ingredientId}/${recipeId}/${mealPlan}/`
    return api.post(url, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  addAisleItemToShoppingList(mealPlan, userWeeklyPlanId, ids, cids, id) {
    var payload = {
      ids: ids,
      cids: cids,
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${SHOPPING_LIST}${ADD_AISLE_TO_SHOPPING_LIST}${id}/${mealPlan}/`
    return api.post(url, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  removeAisleItemFromShoppingList(mealPlan, userWeeklyPlanId, ids, cids, id) {
    var payload = {
      ids: ids,
      cids: cids,
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${SHOPPING_LIST}${REMOVE_AISLE_FROM_SHOPPING_LIST}${id}/${mealPlan}/`
    return api.post(url, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  addCustomShoppingListItem(
    mealPlan,
    userWeeklyPlanId,
    name,
    unitId,
    aisleId,
    quantity
  ) {
    var payload = {
      meal_plan_id: mealPlan,
      user_weekly_plan_id: userWeeklyPlanId,
      name,
      unit_id: unitId,
      quantity,
      aisle_id: aisleId,
    }
    return api.post(ADD_CUSTOM_SHOPPING_LIST_ITEM, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  removeCustomShoppingListItem(mealPlan, userWeeklyPlanId, id) {
    var payload = {
      custom_shopping_list_item_id: id,
      meal_plan_id: mealPlan,
      user_weekly_plan_id: userWeeklyPlanId,
    }
    return api.post(REMOVE_CUSTOM_SHOPPING_LIST_ITEM, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  buyOnAmazonFresh(mealPlan, userWeeklyPlanId) {
    var params = {
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${SHOPPING_LIST}${AMAZON_FRESH_ITEMS}${mealPlan}`
    return api.get(url, params).then((res) => {
      return res.data
    })
  },
  getShoppingListByAisle(mealPlan, userWeeklyPlanId) {
    var params = {
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${SHOPPING_LIST}${BY_AISLE}${mealPlan}`
    return api.get(url, params).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  /**
   * mealPlan and userWeeklyPlanId are only placeholders to preserve the same function singature for non-preview functions
   * @param mealPlan
   * @param userWeeklyPlanId
   * @param previewParams
   */
  getPreviewShoppingListByAisle(mealPlan, userWeeklyPlanId, previewParams) {
    const url = `${SHOPPING_LIST}${BY_AISLE_PREVIEW}${previewParams.mealPlanId}/${previewParams.token}/${previewParams.intolerance}/`
    return api.get(url).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  getShoppingListByRecipes(mealPlan, userWeeklyPlanId) {
    var params = {
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${SHOPPING_LIST}${BY_RECIPE}${mealPlan}`
    return api.get(url, params).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  /**
   * mealPlan and userWeeklyPlanId are only placeholders to preserve the same function singature for non-preview functions
   * @param mealPlan
   * @param userWeeklyPlanId
   * @param previewParams
   */
  getPreviewShoppingListByRecipes(mealPlan, userWeeklyPlanId, previewParams) {
    const url = `${SHOPPING_LIST}${BY_RECIPE_PREVIEW}${previewParams.mealPlanId}/${previewParams.token}/${previewParams.intolerance}/`
    return api.get(url).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  getShoppingListTips(mealPlan, userWeeklyPlanId) {
    var params = {
      user_weekly_plan_id: userWeeklyPlanId,
    }
    const url = `${SHOPPING_LIST}${TIPS}${mealPlan}`
    return api.get(url, params).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  /**
   * mealPlan and userWeeklyPlanId are only placeholders to preserve the same function singature for non-preview functions
   * @param mealPlan
   * @param userWeeklyPlanId
   * @param previewParams
   */
  getPreviewShoppingListTips(mealPlan, userWeeklyPlanId, previewParams) {
    const url = `${SHOPPING_LIST}${TIPS_PREVIEW}${previewParams.mealPlanId}/${previewParams.token}/${previewParams.intolerance}/`
    return api.get(url).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  getAisleList() {
    return api.get(AISLE_LIST).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  getUnitList() {
    return api.get(UNIT_LIST).then((res) => {
      return camelizeKeys(res.data)
    })
  },
}
