<template>
  <MPModal :show.sync="showModal" :show-loading="inProgress" :showX="true">
    <form class="text-center">
      <h4 class="center">Update Payment Information</h4>
      <form class="mt-3" @submit.prevent="onFormSubmit">
        <div class="stripe-card-container">
          <div ref="stripeCard"></div>
        </div>
        <span class="error-message" v-if="cardErrorMessage">
          {{ cardErrorMessage }}
        </span>
        <span
          class="error-message"
          v-if="formErrorMessage"
          v-html="formErrorMessage"
        ></span>
        <MPButton class="mt-3" color="primary" size="large" type="submit">
          Update information
        </MPButton>
      </form>
    </form>
  </MPModal>
</template>

<script>
import environment from '@/environment'
import _ from 'lodash'

export default {
  name: 'UpdatePaymentModal',
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inProgress: false,
      cardError: null,
    }
  },
  computed: {
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
    cardErrorMessage() {
      if (this.cardError) {
        return this.cardError.message
      } else return null
    },
    formErrorMessage() {
      if (this.formError) {
        const defaultMassage =
          'Oops, looks like something went wrong. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.'
        return _.get(
          this.formError,
          'formError.response.data.detail',
          defaultMassage
        )
      }
      return null
    },
  },
  methods: {
    onFormSubmit() {
      this.inProgress = true
      this.stripe
        .createToken(this.card)
        .then(({ error, token }) => {
          this.removeErrors()
          if (error) {
            this.cardError = error
            return Promise.resolve()
          } else {
            return this.$store
              .dispatch('user/changeBillingCard', token.id)
              .then(() => (this.showModal = false))
          }
        })
        .then(() => {
          this.inProgress = false
        })
    },
    removeErrors() {
      this.cardError = null
      this.formError = null
    },
  },
  mounted() {
    // Stripe card
    this.stripe = window.Stripe(environment.stripeKey)
    let elements = this.stripe.elements()
    this.card = elements.create('card', {
      style: stripeElementsStyle,
      hidePostalCode: true,
    })
    this.card.mount(this.$refs.stripeCard)

    this.card.addEventListener('change', (event) => {
      this.removeErrors()
      if (event.error) this.cardError = event.error
    })
  },
}

let stripeElementsStyle = {
  base: {
    backgroundColor: '#ffffff',
    color: '#4c6979',
    borderRadius: '3px',
    lineHeight: '18px',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#9b9b9b',
    },
  },
  invalid: {
    color: '#df171d',
    iconColor: '#df171d',
  },
}
</script>

<style lang="scss" scoped>
.stripe-card-container {
  border: solid 0.5px #e5e5e5;
  border-radius: 3px;
  padding: 20px;
  min-height: 49px;
}
.error-message {
  color: $error;
  font-size: 12px;
  line-height: 1.5rem;
  min-height: 1.5rem;
  display: block;
  text-align: center;
  margin-top: 2px;
}
</style>

<style lang="scss">
.payment-card-description {
  .cross {
    text-decoration: line-through;
  }

  .green {
    color: $primary;
  }
}
</style>
