import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Login from '@/views/Login.vue'
import Upgrade from '@/views/Upgrade'
import Password from '@/views/Password'
import * as Sentry from '@sentry/browser'
// old register process
// import Register from '@/views/Register'
import PaywallRegister from '@/views/PaywallRegister'
import Signup from '@/views/Signup'
import Unsubscribe from '@/views/Unsubscribe'
import AddRecipeBox from '@/views/AddRecipeBox'
import LandingEricAdams from '@/views/LandingEricAdams'
import OnboardingTutorial from '@/views/OnboardingTutorial'
import Welcome from '@/views/Welcome'
import PasswordResetForm from '@/components/credentials/passwordreset/PasswordResetForm'
import PasswordChangeForm from '@/components/credentials/passwordreset/PasswordChangeForm'
import registrationStepRutes from '@/components/credentials/register/routes'
import reverseRegistrationStepRutes from '@/components/credentials/register/reverseSignupRoutes'
import SideNavbar from '@/components/navigation/SideNavbar'
import BottomNavbar from '@/components/navigation/BottomNavbar'
import RecipeView from '@/views/RecipeView'
import TheRecipeAdd from '@/components/recipe/TheRecipeAdd'
import RecipeOverlay from '@/views/RecipeOverlay'
import TheSettings from '@/components/settings/TheSettings'
import SettingsProfile from '@/components/settings/SettingsProfile'
import SettingsPreferences from '@/components/settings/SettingsPreferences'
import SettingsBilling from '@/components/settings/SettingsBilling'
import SettingsNotifications from '@/components/settings/SettingsNotifications'
import SettingsAffiliate from '@/components/settings/SettingsAffiliate'
import DeletionInstructions from '@/views/DeletionInstructions'
import { resetState } from './store'
import { events } from '@/utils/mixpanel.events'
import CheckoutRegister from '@/views/CheckoutRegister.vue'
import ReactivePlan from '@/views/ReactivePlan.vue'

Vue.use(Router)

const PersonalRecipes = () =>
  import(
    /* webpackChunkName: "personal-recipes" */ './views/PersonalRecipes.vue'
  )
const RecipeBox = () =>
  import(/* webpackChunkName: "recipe-box" */ './views/RecipeBox.vue')
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue')
const PreviewDashboard = () =>
  import(
    /* webpackChunkName: "preview-dashboard" */ './views/PreviewDashboard.vue'
  )
const GroceryList = () =>
  import(/* webpackChunkName: "grocery-list" */ './views/GroceryList.vue')
const PreviewGroceryList = () =>
  import(
    /* webpackChunkName: "preview-grocery-list" */ './views/PreviewGroceryList.vue'
  )
const WeekendPrep = () =>
  import(/* webpackChunkName: "weekend-prep" */ './views/WeekendPrep.vue')
const PreviewWeekendPrep = () =>
  import(
    /* webpackChunkName: "preview-weekend-prep" */ './views/PreviewWeekendPrep.vue'
  )
// const Premium = () =>
//   import(/* webpackChunkName: "premium" */ './views/Premium.vue')
const PaywallPremium = () =>
  import(/* webpackChunkName: "paywall-premium" */ './views/PaywallPremium.vue')
const SwitchPlan = () =>
  import(/* webpackChunkName: "switch-plan" */ './views/SwitchPlan.vue')
const BuyOnAmazon = () =>
  import(/* webpackChunkName: "buy-on-amazon" */ './views/BuyOnAmazon.vue')
const FacebookGroup = () =>
  import(/* webpackChunkName: "facebook-group" */ './views/FacebookGroup.vue')
const LinkGenerator = () =>
  import(/* webpackChunkName: "link-generator" */ './views/LinkGenerator.vue')
const Gift = () => import(/* webpackChunkName: "gift" */ './views/Gift.vue')
const Physician = () =>
  import(/* webpackChunkName: "physician" */ './views/Physician.vue')
const LoginOrSignup = () =>
  import(/* webpackChunkName: "loginorsignup" */ './views/LoginOrSignup.vue')

function loadRecipeOnRouteEnter(to, from, next) {
  const { recipeId, token } = to.params

  store.dispatch('recipe/loadRecipe', { recipeId, token })
  const payload = {
    eventName: events.recipe.view,
  }
  store.dispatch('analytics/track', payload)
  next()
}

/**
 * If user is already logged in, redirect him to /plan.
 * This function is currently used for login pages only.
 */
function redirectToPlanIfLoggedIn(to, from, next) {
  if (isLoggedIn()) {
    next({ path: '/plan', query: to.query })
  } else {
    next()
  }
}

/**
 * If user is already logged in and is not active, redirect him to /premium.
 */
// function redirectToPremiumIfLoggedIn(to, from, next) {
//   if (isLoggedIn()) {
//     store.getters['user/userDataInitPromise'].then(() => {
//       if (isActiveUser()) {
//         next({ path: '/plan', query: to.query })
//       } else {
//         next({ path: '/premium', query: to.query })
//       }
//     })
//   } else {
//     next()
//   }
// }

/**
 * Set meal plan type if parameter exists.
 * Currently we allow only `next` parameter to be parsed.
 */
function setMealPlanTypeIfExist(to, from, next) {
  if (to.params['mealPlanType']) {
    if (to.params['mealPlanType'] == 'next') {
      store.dispatch('globals/setMealPlanData', { mealPlan: 'next' })
    }
    next({
      ...to,
      ...{
        params: { mealPlanType: null },
      },
    })
  } else {
    next()
  }
}

function premiumRedirects(to, from, next) {
  store.getters['user/userDataInitPromise'].then(() => {
    const premiumViewType = store.getters['user/premiumViewType']

    const pageMapper = {
      gift: 'gift',
      reactivate: 'switch-plan',
      switch: 'switch-plan',
      upgrade: 'premium',
      inapp: 'SettingsBilling',
    }
    const premiumViewTypePageName = pageMapper[premiumViewType]

    if (premiumViewTypePageName !== to.name) {
      next({ name: premiumViewTypePageName })
    } else {
      next()
    }
  })
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      components: {
        default: Login,
      },
      beforeEnter: redirectToPlanIfLoggedIn,
    },
    {
      path: '/logout',
      components: {},
      beforeEnter: (to, from, next) => {
        resetState()
        next({ path: '/login' })
      },
    },
    {
      path: '/login-full',
      components: {
        default: Login,
      },
      props: {
        default: {
          showBackToFok: true,
        },
      },
      beforeEnter: redirectToPlanIfLoggedIn,
    },
    {
      path: '/upgrade',
      components: {
        default: Upgrade,
      },
    },
    {
      path: '/physician',
      components: {
        default: Physician,
      },
    },
    {
      path: '/loginorsignup',
      components: {
        default: LoginOrSignup,
      },
    },
    {
      path: '/landing-eric-adams',
      components: {
        default: LandingEricAdams,
      },
      beforeEnter: redirectToPlanIfLoggedIn,
    },
    {
      path: '/register',
      components: {
        // default: Register
        default: CheckoutRegister,
      },
      children: registrationStepRutes,
      beforeEnter(to, from, next) {
        if (isLoggedIn()) {
          redirectToPlanIfLoggedIn(to, from, next)
        } else next()
      },
    },
    {
      path: '/join',
      components: {
        default: PaywallRegister,
      },
      children: registrationStepRutes,
      beforeEnter(to, from, next) {
        if (isLoggedIn()) {
          redirectToPlanIfLoggedIn(to, from, next)
        } else next()
      },
    },
    {
      path: '/signup',
      components: {
        default: Signup,
      },
      children: reverseRegistrationStepRutes,
    },
    {
      path: '/password',
      components: {
        default: Password,
      },
      children: [
        {
          path: 'reset',
          component: PasswordResetForm,
        },
        {
          path: 'reset/confirm/:uid/:token',
          component: PasswordChangeForm,
        },
      ],
    },
    {
      path: '/unsubscribe/:token/:option?',
      components: {
        default: Unsubscribe,
      },
    },
    {
      path: '/tutorial/',
      components: {
        default: OnboardingTutorial,
        sidebar: SideNavbar,
      },
      meta: { requiresAuth: true },
    },
    {
      path: '/welcome',
      components: {
        default: Welcome,
      },
      meta: { requiresAuth: true },
    },
    {
      name: 'RecipeView',
      path: '/recipe/:recipeId/:recipeName',
      components: {
        default: RecipeView,
      },
      beforeEnter: loadRecipeOnRouteEnter,
      meta: { requiresAuth: true },
    },
    {
      path: '/recipe-preview/:recipeId/:token',
      beforeEnter: loadRecipeOnRouteEnter,
      components: {
        default: RecipeView,
      },
    },
    {
      name: 'AddPersonalRecipe',
      path: '/recipe/add',
      component: TheRecipeAdd,
      meta: { requiresAuth: true },
    },
    {
      path: '/recipe-box',
      name: 'recipeBox',
      meta: { requiresAuth: true },
      components: {
        default: RecipeBox,
        sidebar: SideNavbar,
        bottombar: BottomNavbar,
      },
      children: [
        {
          name: 'RecipeBoxRecipe',
          path: '/recipe/:recipeId/:recipeName',
          component: RecipeOverlay,
          beforeEnter: loadRecipeOnRouteEnter,
        },
      ],
    },
    {
      path: '/personal-recipes',
      name: 'personalRecipes',
      meta: { requiresAuth: true },
      components: {
        default: PersonalRecipes,
        sidebar: SideNavbar,
        bottombar: BottomNavbar,
      },
      children: [
        {
          name: 'personalRecipeRecipe',
          path: '/recipe/:recipeId/:recipeName',
          component: RecipeOverlay,
          beforeEnter: loadRecipeOnRouteEnter,
        },
      ],
    },
    {
      path: '/plan/:mealPlanType?',
      name: 'plan',
      meta: { requiresAuth: true },
      components: {
        default: Dashboard,
        sidebar: SideNavbar,
        bottombar: BottomNavbar,
      },
      beforeEnter: setMealPlanTypeIfExist,
      children: [
        {
          name: 'PlanRecipe',
          path: '/recipe/:recipeId/:recipeName',
          component: RecipeOverlay,
          beforeEnter: loadRecipeOnRouteEnter,
        },
      ],
    },
    {
      // /preview-plan/160/xOIaAr7Ra3/no/
      path: '/preview-plan/:mealPlanId/:token/:intolerance/:pressureCooker?',
      name: 'PreviewPlan',
      components: {
        default: PreviewDashboard,
      },
    },
    {
      path: '/plan/add-recipe/:day/:mealType?',
      name: 'AddRecipeBox',
      meta: { requiresAuth: true },
      components: {
        default: AddRecipeBox,
      },
      children: [
        {
          name: 'AddRecipeRecipe',
          path: '/recipe/:recipeId/:recipeName',
          component: RecipeOverlay,
          beforeEnter: loadRecipeOnRouteEnter,
        },
      ],
    },
    {
      path: '/grocery-list',
      name: 'groceryList',
      meta: { requiresAuth: true },
      components: {
        default: GroceryList,
        sidebar: SideNavbar,
        bottombar: BottomNavbar,
      },
    },
    {
      // preview-shopping-list/158/xOIaAr7Ra3/no
      path: '/preview-shopping-list/:mealPlanId/:token/:intolerance/:pressureCooker?',
      name: 'PreviewGroceryList',
      components: {
        default: PreviewGroceryList,
      },
    },
    {
      path: '/buy-amazon/:mealPlan/:userWeeklyPlanId',
      name: 'buyAmazon',
      components: {
        default: BuyOnAmazon,
        sidebar: SideNavbar,
        bottombar: BottomNavbar,
      },
    },
    {
      path: '/prep-ahead',
      name: 'prepAhead',
      meta: { requiresAuth: true },
      components: {
        default: WeekendPrep,
        sidebar: SideNavbar,
        bottombar: BottomNavbar,
      },
      children: [
        {
          name: 'PrepAheadRecipe',
          path: '/recipe/:recipeId',
          component: RecipeOverlay,
          beforeEnter: loadRecipeOnRouteEnter,
        },
        {
          name: 'PrepAheadRecipe',
          path: '/recipe/:recipeId/:recipeName',
          component: RecipeOverlay,
          beforeEnter: loadRecipeOnRouteEnter,
        },
      ],
    },
    {
      // preview-prep-ahead/158/xOIaAr7Ra3/no/
      path: '/preview-prep-ahead/:mealPlanId/:token/:intolerance/:pressureCooker?',
      name: 'PreviewWeekendPrep',
      components: {
        default: PreviewWeekendPrep,
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      meta: { requiresAuth: true },
      components: {
        default: TheSettings,
        sidebar: SideNavbar,
        bottombar: BottomNavbar,
      },
      children: [
        {
          name: 'SettingsProfile',
          path: '/settings/profile',
          component: SettingsProfile,
        },
        {
          name: 'SettingsPreferences',
          path: '/settings/preferences',
          component: SettingsPreferences,
        },
        {
          name: 'SettingsBilling',
          path: '/settings/billing',
          component: SettingsBilling,
        },
        {
          name: 'SettingsNotifications',
          path: '/settings/notifications',
          component: SettingsNotifications,
        },
        {
          name: 'SettingsAffiliate',
          path: '/settings/affiliate',
          component: SettingsAffiliate,
          beforeEnter: (to, from, next) => {
            if (store.getters['user/isAffiliate']) {
              store.dispatch('Affiliate/refresh')
              next()
            } else next('/plan')
          },
        },
      ],
    },
    {
      path: '/payment',
      name: 'payment',
      meta: { requiresAuth: false },
      components: {
        default: PaywallPremium,
      },
    },
    // {
    //   path: '/premium',
    //   name: 'premium',
    //   meta: { requiresAuth: true },
    //   components: {
    //     default: Premium
    //   },
    //   beforeEnter: premiumRedirects
    // },
    {
      path: '/reactivate',
      name: 'reactivate',
      meta: { requiresAuth: true },
      components: {
        default: ReactivePlan,
      },
    },
    {
      path: '/switch-plan',
      name: 'switch-plan',
      meta: { requiresAuth: true },
      components: {
        default: SwitchPlan,
      },
      beforeEnter: premiumRedirects,
    },
    {
      path: '/gift',
      name: 'gift',
      meta: { requiresAuth: true },
      components: {
        default: Gift,
      },
    },
    {
      path: '/facebook-group',
      name: 'facebook-group',
      meta: { requiresAuth: true },
      components: {
        default: FacebookGroup,
      },
    },
    {
      path: '/link-generator',
      name: 'link-generator',
      components: {
        default: LinkGenerator,
      },
    },
    {
      path: '/extend-trial/success',
      name: 'extend-trial-success',
      components: {
        default: Dashboard,
      },
      beforeEnter(to, from, next) {
        if (isLoggedIn()) {
          const message = {
            text: 'Your trial has been extended for 14 days. Let’s get cooking!',
            type: 'action',
          }
          store.dispatch('headers/setExtendTrialMessage', message, {
            root: true,
          })
        }
        return redirectToPlanIfLoggedIn(to, from, next)
      },
    },
    {
      path: '/delete-instructions',
      name: 'delete-instructions',
      components: {
        default: DeletionInstructions,
      },
    },
    {
      path: '/extend-trial/:token',
      name: 'extend-trial-token',
      components: {
        default: Dashboard,
      },
      beforeEnter(to, from, next) {
        if (isLoggedIn()) {
          extendTrial(to.params.token, next)
        } else next({ path: '/login', query: { redirect: to.path } })
      },
    },
    {
      path: '*',
      beforeEnter(to, from, next) {
        Sentry.captureMessage(to.path, Sentry.Severity.Warning)
        if (isLoggedIn()) {
          next({ path: '/plan', query: to.query })
        } else {
          next({ path: '/login', query: to.query })
        }
      },
    },
  ],
  scrollBehavior,
})

function isLoggedIn() {
  return store.state.user.accessToken
}

// function isActiveUser() {
//   return store.getters['user/isActive']
// }

const urlMapper = {
  '#!/archive': '/recipe-box',
  '#!/settings': '/settings/profile',
  '#!/shopping-list': '/grocery-list',
  '#!/settings?scrollTo=profile': '/settings/profile',
  '#!/settings?scrollTo=preferences': '/settings/preferences',
  '#!/settings?scrollTo=billing': '/settings/billing',
  '#!/settings?scrollTo=notifications': '/settings/notifications',
  '#!/settings?scrollTo=affiliate': '/settings/affiliate',
}

function replaceAngularLinking(to, next) {
  if (to.hash.indexOf('#!') > -1) {
    if (urlMapper[to.hash]) {
      next({
        path: urlMapper[to.hash],
      })
    } else {
      next({
        path: to.hash.replace('#!', ''),
        query: to.query,
      })
    }
  }
}

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    return savedPosition
  } else {
    return {
      y: to.params.scrollTop || 0,
    }
  }
}

router.beforeEach((to, from, next) => {
  replaceAngularLinking(to, next)
  store.dispatch('globals/setPreviousRoute', from)
  Vue.prototype.$posthog.sendEvent('$pageview', to.fullPath)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: '/login',
        query: { ...to.query, redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

async function extendTrial(token, next) {
  await store.dispatch('headers/setExtendTrialToken', token)
  next({ path: '/plan' })
}

export default router
